import React from "react";
import "./Layout.scss";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, LinkType } from "../Navigation/Drawer/Drawer";
import { ToastSuccess as Toast } from "../Toast/Toast";
import { Header } from "../Header/Header";
import { selectNotifyFlag, selectNotifyStatus } from "../../store/notify/notifiesSelectors";
import { notifyActions } from "../../store/notify";
import iconInvestigation from "../../assets/iconInvestigation/iconInvestigation.svg";
import iconStory from "../../assets/iconStory/iconStory.svg";
import iconJob from "../../assets/iconJob/iconJob.svg";
import iconAbout from "../../assets/about/about.svg";
import settingsIcon from "../../assets/settings/settings-icon.svg";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { localStorageKeyList } from "../../constants/storage";
import { appRoutes } from "../../constants/routes";
import { ChartIcon } from "../Icons/ChartIcon";

export const Layout: React.FC = ({ children }) => {
	const dispatch = useDispatch();
	const { storedValue: menu, setValue: setMenu } = useLocalStorage(localStorageKeyList.drawer, false);

	const notifyStatus = useSelector(selectNotifyStatus);
	const flag = useSelector(selectNotifyFlag);

	const toggleMenuHandler = () => {
		setMenu((x) => !x);
	};
	const links: LinkType[] = [
		{
			to: `/${appRoutes.investigation}`,
			label: "Расследование",
			exact: false,
			src: iconInvestigation,
		},
		{
			to: `/${appRoutes.story}`,
			label: "История",
			exact: false,
			src: iconStory,
		},
		{
			to: `/${appRoutes.journal}`,
			label: "Журнал",
			exact: false,
			src: iconJob,
		},
		{
			to: `/${appRoutes.report}`,
			label: "Отчёт",
			exact: false,
			icon: <ChartIcon />,
		},
		{
			to: `/${appRoutes.settings}`,
			label: "Настройки",
			exact: false,
			src: settingsIcon,
		},
		{
			to: `/${appRoutes.about}`,
			label: "О программе",
			exact: false,
			src: iconAbout,
		},
	];

	return (
		<div
			className={classNames("layout", {
				"has-open-menu": menu,
			})}
		>
			<Drawer isOpen={menu} linkData={links} onToggleMenu={toggleMenuHandler} />

			<Header />

			<main>{children}</main>
			<Toast
				status={notifyStatus}
				flag={flag}
				title="Расследование завершено"
				setStatus={() => {
					dispatch(notifyActions.setStatusNotify(false));
				}}
				setFlag={(flag) => {
					dispatch(notifyActions.setFlagNotify(flag));
				}}
			/>
		</div>
	);
};
