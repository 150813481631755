import React from "react";
import "./ChartTable.scss";
import { ExternalSystemChart } from "../../../models/externalSystem/response";
import { ColorBox } from "../../UI/ColorBox/ColorBox";
import { convertSizeToTextFormat } from "../../../helpers/commonHelpers/commonHelpers";
import { ScrollBox } from "../../ScrollBox/ScrollBox";
import { ChartTableConstants } from "./ChartTable.constants";
import { Dictionary } from "../../../models/common";

export interface ChartTableProps {
	data: ExternalSystemChart["externalSystems"];
	chartColorList: Dictionary<string>;
}

export const ChartTable: React.FC<ChartTableProps> = ({ data, chartColorList }) => {
	return (
		<div className="chart-table__container">
			<div className="chart-table__row chart-table__row-headers">
				<div className="chart-table__column chart-table__label" />
				<div className="chart-table__column chart-table__count">Количество</div>
				<div className="chart-table__column chart-table__size">Объём</div>
			</div>
			<ScrollBox className="chart-table__row-body">
				{Object.entries(data).map(([key, value]) => {
					return (
						<div className="chart-table__row" key={key}>
							<div
								className="chart-table__column chart-table__label"
								data-testid={ChartTableConstants.Labels}
							>
								{chartColorList[key] && <ColorBox backgroundColor={chartColorList[key]} />}
								<span className="short-text">{key}</span>
							</div>
							<div
								className="chart-table__column chart-table__count"
								data-testid={ChartTableConstants.Counts}
							>
								{value.documentsCount}
							</div>
							<div
								className="chart-table__column chart-table__size"
								data-testid={ChartTableConstants.Sizes}
							>
								{convertSizeToTextFormat(value.documentsSize)}
							</div>
						</div>
					);
				})}
			</ScrollBox>
		</div>
	);
};
