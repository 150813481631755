import qs from "qs";
import { ExternalSystemChart, IExternalSystemList } from "../models/externalSystem/response";
import { EitherResponse } from "../models/common";
import { API_PATH } from "./configs";
import { DocumentsUploadedVolumeRequest } from "../models/externalSystem/request";
import { Api } from "./api";

export const getExternalSystemList = async (): Promise<EitherResponse<IExternalSystemList>> => {
	return await new Api().get<IExternalSystemList>(`/${API_PATH.externalSystems.externalSystems}`);
};

export const getDocumentsUploadedVolume = async (
	queries: DocumentsUploadedVolumeRequest
): Promise<EitherResponse<ExternalSystemChart>> => {
	return await new Api().get<ExternalSystemChart>(`/${API_PATH.externalSystems.documentsUploadedVolume}`, {
		params: queries,
		paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "comma" }),
	});
};
