import { useState } from "react";

interface IUseBooleanReturn {
	value: boolean;
	setTrue: () => void;
	setFalse: () => void;
	setValue: (value: boolean) => void;
	setToggle: () => void;
}

export function useBoolean(defaultValue = false): IUseBooleanReturn {
	const [value, setValue] = useState(defaultValue);

	const setTrue = () => setValue(true);
	const setFalse = () => setValue(false);
	const setToggle = () => setValue(!value);
	const setValueHandle = (v: boolean): void => {
		setValue(v);
	};

	return { value, setValue: setValueHandle, setTrue, setFalse, setToggle };
}
