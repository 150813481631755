import * as Chart from "chart.js";
import { ExternalSystemChart } from "../../models/externalSystem/response";

const COLOR_LIST: ReadonlyArray<string> = [
	"#00A700",
	"#55C300",
	"#F4F400",
	"#F4BF00",
	"#F29400",
	"#FA6201",
	"#FE0204",
	"#DA006D",
	"#7C00A3",
	"#5218D9",
	"#00D3CD",
	"#40B4E7",
];

export function convertExternalSystemsToPieChart(target: ExternalSystemChart["externalSystems"]): Chart.ChartData & {
	colorList: { [key: string]: string };
} {
	const labels: string[] = [];
	const backgroundColor: string[] = [];
	const data: number[] = [];
	const colorList = {} as { [key: string]: string };

	Object.entries(target).forEach(([key, value], index) => {
		labels.push(key);
		data.push(value.documentsSize);

		const currentColor = COLOR_LIST[index] ?? "black";
		colorList[key] = currentColor;
		backgroundColor.push(currentColor);
	});

	return {
		datasets: [
			{
				data,
				backgroundColor,
				borderWidth: 1,
				borderColor: "#F2F4F4",
			},
		],
		labels,
		colorList,
	};
}
