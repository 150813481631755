import React from "react";
import "./Checkbox.scss";

export interface ICheckBoxData {
	name: string;
	checked: boolean;
}

export type IOnChangeCheckbox = ({ name, checked }: ICheckBoxData) => void;

export interface CheckboxProps {
	label: string;
	checked: boolean;
	onChange: IOnChangeCheckbox;
	id: string;
	name: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange, id, name }) => {
	const checkboxId = `checkbox-${id}`;

	const onClickHandle = (): void => {
		const newValue = !checked;
		onChange({ name, checked: newValue });
	};

	return (
		<>
			<div className="checkbox-wrapper make-hover" onClick={onClickHandle}>
				<input
					type="checkbox"
					id={checkboxId}
					checked={checked}
					onChange={(event) => {
						event.stopPropagation();
						onClickHandle();
					}}
				/>
				<label className="text-color_dark" htmlFor={checkboxId} onClick={(e) => e.preventDefault()}>
					{label}
				</label>
			</div>
		</>
	);
};
