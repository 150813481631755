import React from "react";
import { NameValueType, StringCortege } from "../../models/common";
import { DocumentsType } from "../../models/documents/documents";
import { formatDateTime } from "../dates/dates";
import { ICheckBoxData } from "../../components/UI/Checkbox/Checkbox";

export function getNameDocument(
	documentId: string,
	listDocs: Array<{ fileName: string; documentId: string }> = []
): string | null {
	return listDocs.find((val) => val.documentId === documentId)?.fileName ?? null;
}
const DEFAULT_FRACTAL_VALUE = 2;

export function ceilNumber(number: number | string, fractal = DEFAULT_FRACTAL_VALUE): string {
	const x = +parseFloat(number.toString().replace(",", "."));
	const value = number ? x : 0;
	return `${value.toFixed(fractal)}`;
}

export function ceilNumberWithPercent(value: string | number, fractal = DEFAULT_FRACTAL_VALUE): string {
	return `${ceilNumber(value, fractal)}%`;
}

export function truncate(string: string): string {
	const LengthStr = 25;
	if (string.length > LengthStr) {
		return string.substring(0, LengthStr) + "...";
	} else {
		return string;
	}
}

export function isDevelopmentMode(): boolean {
	return process.env.NODE_ENV === "development";
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const removeEmptyFieldsInObject = <T extends object>(target: T): Partial<T> => {
	const result = Object.entries(target).reduce((prev, [key, value]) => {
		if (value !== "" && value !== null && value !== undefined) {
			const k = key as keyof T;
			prev[k] = value;
		}
		return prev;
	}, {} as Partial<T>);
	return result;
};

const transformParameterList = (list: NameValueType[]): Array<StringCortege> => {
	return list.reduce((previousValue, { value, name }) => {
		const res: StringCortege = [name, value];
		previousValue.push(res);
		return previousValue;
	}, [] as Array<StringCortege>);
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function convertObjectToStringCortege(data: object): Array<StringCortege> {
	const SECOND_ARRAY_ITEM = 1;

	return Object.entries(data).reduce((prev, item) => {
		if (typeof item[SECOND_ARRAY_ITEM] !== "object") {
			prev.push(item);
		} else {
			let getParameterList: NameValueType[] = [];

			Object.entries(item[SECOND_ARRAY_ITEM]).forEach((x) => {
				const isSecondItemArray = Array.isArray(x[SECOND_ARRAY_ITEM]);
				if (typeof x[SECOND_ARRAY_ITEM] !== "object" && !isSecondItemArray) {
					prev.push(x as StringCortege);
				}
				if (isSecondItemArray) {
					const target = x[SECOND_ARRAY_ITEM] as NameValueType[];
					getParameterList = [...getParameterList, ...target];
				}
			});
			const convertGetItem = transformParameterList(getParameterList);
			prev.push(...convertGetItem);
		}
		return prev;
	}, [] as Array<StringCortege>);
}

export function formatItemSelect(element: DocumentsType): string {
	const dateTime = formatDateTime(element.created);
	return `${element.fileName} [Время загрузки: ${dateTime}]`;
}

export const DEFAULT_FORMAT_NUMBER_FIXED_COUNT = 1;

export enum SIZE {
	B = "B",
	KB = "KB",
	MB = "MB",
	GB = "GB",
}

export function getFormatNumber({ value, type = "B" }: { value: number; type?: SIZE[keyof SIZE] }): string {
	return `${value.toFixed(DEFAULT_FORMAT_NUMBER_FIXED_COUNT)} ${type}`;
}

const OFFSET = 1024;

const B = 1023.99;
const KB = OFFSET;
const MB = KB * OFFSET;
const GB = MB * OFFSET;

export const size = {
	B,
	KB,
	MB,
	GB,
	OFFSET,
};

export function convertSizeToTextFormat(value: number): string {
	const convertValue = Number(value.toFixed(DEFAULT_FORMAT_NUMBER_FIXED_COUNT));

	if (convertValue >= size.KB && convertValue < size.MB) {
		return getFormatNumber({
			value: convertValue / size.KB,
			type: SIZE.KB,
		});
	}
	if (convertValue >= size.MB && convertValue < size.GB) {
		return getFormatNumber({
			value: convertValue / size.MB,
			type: SIZE.MB,
		});
	}

	if (convertValue >= size.GB) {
		return getFormatNumber({
			value: convertValue / size.GB,
			type: SIZE.GB,
		});
	}

	return getFormatNumber({
		value: convertValue,
	});
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function generateCheckboxFromDictionary<T extends object>(target: T): Array<ICheckBoxData> {
	return Object.keys(target).map((x) => {
		return {
			name: x,
			checked: true,
		};
	});
}

export function generateCheckboxFromList(target: string[], makeChecked = true): Array<ICheckBoxData> {
	return target.map((x) => {
		return {
			name: x,
			checked: makeChecked,
		};
	});
}

export function getSelectedCheckboxList(target: Array<ICheckBoxData>): string[] {
	return target.reduce((previousValue, currentValue) => {
		if (currentValue.checked) {
			previousValue.push(currentValue.name);
		}

		return previousValue;
	}, [] as string[]);
}

export function htmlDomNodeSetCssVariable(
	node: React.MutableRefObject<HTMLDivElement | null>,
	styles: { [key: string]: string | number }
): void {
	Object.entries(styles).forEach(([key, value]) => {
		node.current?.style.setProperty(`--${key}`, value.toString());
	});
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noopHandle = (): void => {};
