import React from "react";
import classNames from "classnames";
import { useBoolean } from "../../../../hooks/useBoolean";

interface IInputForm {
	label: string;
	defaultValue: string;
	onChange(e: React.ChangeEvent<HTMLInputElement>): void;
	onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	id: string;
	name: string;
}

export const InputForm: React.FC<IInputForm> = (props) => {
	const { label, defaultValue, onChange, id, name, onKeyPress } = props;
	const { value: active, setTrue: setActiveTrue, setFalse: setActiveFalse } = useBoolean(!!defaultValue);
	return (
		<div
			className={classNames("input-text__wrapper", {
				active,
			})}
		>
			<label
				htmlFor={id}
				className={classNames({
					"label-inside": !active,
					"label-outside": active || !!defaultValue,
				})}
			>
				{label}
			</label>
			<input
				name={name}
				id={id}
				type="text"
				defaultValue={defaultValue}
				onFocus={setActiveTrue}
				onKeyPress={onKeyPress}
				onChange={(e) => {
					setActiveFalse();
					onChange(e);
				}}
				onBlur={setActiveFalse}
			/>
		</div>
	);
};
