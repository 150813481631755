import React from "react";
import "./About.scss";
import { Contacts } from "../../components/Contacts/Contacts";
import { License } from "../../components/License/License";

export const About: React.FC = () => {
	return (
		<div className="about-container">
			<License />
			<Contacts />
		</div>
	);
};
