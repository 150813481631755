import React, { HTMLAttributes } from "react";
import "./InformationBlock.scss";
import { InformationBlockConstants } from "./constants";

type Props = HTMLAttributes<HTMLDivElement>;

export const InformationBlock: React.FC<Props> = ({ children, ...props }) => {
	return (
		<div className="information-block" data-testid={InformationBlockConstants.Container} {...props}>
			{children}
		</div>
	);
};
