import React, { useEffect, useRef } from "react";

interface IUseComponentVisible {
	ref: React.MutableRefObject<HTMLDivElement | null>;
}

export function useComponentVisible(onClickOutlined: () => void): IUseComponentVisible {
	const ref = useRef<HTMLDivElement | null>(null);

	const handleClickOutside = (event: any) => {
		if (ref.current && !ref.current.contains(event.target)) {
			onClickOutlined();
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside, true);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside, true);
		};
	});

	return { ref };
}
