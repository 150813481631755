import React from "react";
import Markdown from "markdown-to-jsx";
import { AboutService } from "../../api/about";
import "./ReleaseNotes.scss";
import { ReleaseNotesConstants } from "./constants";

export const ReleaseNotes: React.FC = () => {
	const [notes, setNotes] = React.useState("");
	React.useEffect(() => {
		AboutService.getReleaseNotes().then((response) => {
			response.map((data) => {
				setNotes(data);
				return data;
			});
		});
	}, []);
	return (
		<>
			<div className="release-notes_container">
				{notes && (
					<div data-testid={ReleaseNotesConstants.Container}>
						<Markdown children={notes} />
					</div>
				)}
			</div>
		</>
	);
};
