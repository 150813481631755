import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classNames from "classnames";
import { useBoolean } from "../../../hooks/useBoolean";
import { DATE_FORMAT } from "../../../constants/common";
import { DropdownIcon } from "../Icons/DropdownIcon";
import { useComponentVisible } from "../../../hooks/useComponentVisible";

interface Props {
	changeDate?: (
		{ date, name }: { date: Date; name: string },
		event?: React.SyntheticEvent<HTMLInputElement> | React.KeyboardEvent<HTMLDivElement>
	) => void;
	mark: string;
	selected: Date | null;
	minDate?: Date | null;
	maxDate?: Date | null;
	name?: string;
}

export const InputDate: React.FC<Props> = ({ changeDate, mark, selected, minDate, maxDate, name = "" }) => {
	const { value, setTrue, setFalse } = useBoolean();
	const [dateValue, setDateValue] = React.useState<Date | null>(null);
	const { ref } = useComponentVisible(setFalse);

	return (
		<div
			className={classNames("date-picker__custom-wrapper", {
				active: value,
			})}
			onClick={setTrue}
			ref={ref}
		>
			<span className="date-picker_label">{mark}:</span>
			<DatePicker
				open={value}
				dateFormat={DATE_FORMAT}
				selected={selected}
				onChange={(date: Date, event) => {
					changeDate?.({ date: date ?? dateValue, name }, event);
					setDateValue(date);
					setFalse();
				}}
				onBlur={(event) => {
					event.stopPropagation();
					setFalse();
				}}
				minDate={minDate}
				maxDate={maxDate}
				popperClassName="date-picker_custom-popper"
			/>
			<div className="date-picker_icon-wrapper">
				<DropdownIcon />
			</div>
		</div>
	);
};
