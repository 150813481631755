import { EitherResponse } from "../models/common";
import { NotificationsSettingsResponse } from "../models/notificationsSettings/response";
import { Api } from "./api";
import { API_PATH } from "./configs";

export class SettingsService {
	public static async getNotificationsSettings(): Promise<EitherResponse<NotificationsSettingsResponse>> {
		return await new Api().get<NotificationsSettingsResponse>(`${API_PATH.settings.notificationSettings}`);
	}

	public static async setNotificationsSettings(data: NotificationsSettingsResponse): Promise<EitherResponse<null>> {
		return await new Api().put<NotificationsSettingsResponse, null>(
			`/${API_PATH.settings.notificationSettings}`,
			data
		);
	}
}
