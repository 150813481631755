import React, { useRef } from "react";
import "./Table.scss";
import { Column, Row, TableOptions, TableRowProps, usePagination, useTable } from "react-table";
import classNames from "classnames";
import { TableConstants, TablePaginationImageConstants } from "./constants";
import { FilterInputProps, TableFilter } from "./TableFilter/TableFilter";
import { Pagination } from "../../models/common";

export type CustomTableOnChangePage = ({ pageNumber, itemsCount }: { pageNumber: number; itemsCount: number }) => void;

export interface TableButtonType {
	disabledLink: boolean;
	clickLink: () => void;
}

export interface SubCustomTableProps {
	pagination?: Partial<Pagination> | null;
	onChangePage?: CustomTableOnChangePage;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface Props<T extends object> extends TableOptions<T>, SubCustomTableProps {
	columns: Column<T>[];
	data: T[];
	oldPagination?: {
		self: TableButtonType;
		first: TableButtonType;
		previous: TableButtonType;
		next: TableButtonType;
		last: TableButtonType;
		totalCount?: number;
		pageCount?: number;
		page?: number;
		itemCount?: number;
	};
	onChangePage?: CustomTableOnChangePage;

	getNewRowProps?: (row: Row<T>) => TableRowProps;
	paginationContent?: React.ReactElement;
}

const MIN_ITEM_HEIGHT = 46;

// eslint-disable-next-line @typescript-eslint/ban-types
export type TableProps<T extends { [key: string]: string } = {}> = Props<T>;

// eslint-disable-next-line @typescript-eslint/ban-types
export const Table = <T extends object>({
	columns,
	data,
	oldPagination,

	getNewRowProps,
	pagination,
	onChangePage,
	paginationContent,
}: Props<T>): React.ReactElement => {
	const tableWrapperRef = React.useRef<HTMLDivElement | null>(null);
	const headerRef = React.useRef<HTMLTableSectionElement | null>(null);
	const bodyRef = React.useRef<HTMLTableSectionElement | null>(null);
	const paginationRef = React.useRef<HTMLDivElement | null>(null);
	const [tableItemCount, setTableItemCount] = React.useState<number | null>(null);
	const [bodyRowHeight, setBodyRowHeight] = React.useState<number>(0);

	const { self, first, previous, next, last } = oldPagination || {};

	const totalCount = oldPagination?.totalCount ?? pagination?.totalCount;
	const page = oldPagination?.page ?? pagination?.page ?? 0;
	const pageCount = oldPagination?.pageCount ?? pagination?.pageCount ?? 1;

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		gotoPage,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		canPreviousPage,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		canNextPage,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		nextPage,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		previousPage,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		state: { pageIndex },
	} = useTable<T>(
		{
			columns,
			data,
			pageCount,
			manualPagination: true,

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			initialState: { pageIndex: page },
		},
		usePagination
	);

	const tableBodyRef = useRef<null | HTMLDivElement>(null);

	const currentPageIndex = pageIndex > pageCount ? 0 : pageIndex;

	const getItemCount = () => {
		setTimeout(() => {
			const totalHeight = tableWrapperRef.current?.getBoundingClientRect().height ?? 0;
			const tableHeaderHeight = 78.5;
			const paginationHeight = 41;
			const bodyHeight = totalHeight - tableHeaderHeight - paginationHeight;

			const tableItemCount = Math.ceil(bodyHeight / MIN_ITEM_HEIGHT);
			const itemHeight = bodyHeight / tableItemCount;

			setBodyRowHeight(itemHeight);

			setTableItemCount(tableItemCount);
		}, 100);
	};

	React.useEffect(getItemCount, []);

	const getItemsOnCurrentPage = (currentPage = 0): void => {
		onChangePage?.({
			pageNumber: currentPage,
			itemsCount: tableItemCount ?? 0,
		});
	};

	const onGetItemCount = () => {
		if (tableItemCount) {
			getItemsOnCurrentPage();
		}
	};

	React.useEffect(onGetItemCount, [tableItemCount]);

	const FIRST_PAGE = 0;

	const headerColumns: typeof headerGroups = [];
	headerColumns.push(headerGroups[1]);

	type HeaderColumn = typeof headerGroups[number];
	type CustomHeaderColumn = HeaderColumn & {
		filterConfigs?: FilterInputProps;
		hasFilter?: boolean;
	};

	return (
		<div
			data-testid={TableConstants.Container}
			className={classNames("main-table", {
				"has-pagination": pagination,
			})}
			ref={tableWrapperRef}
		>
			<div className={classNames("main-table_body")} ref={tableBodyRef}>
				<table {...getTableProps()}>
					<thead ref={headerRef}>
						{headerColumns.map((headerGroup) => {
							return (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column: CustomHeaderColumn) => {
										const filterConfigs = column?.filterConfigs;

										return (
											<th {...column.getHeaderProps()}>
												<div
													style={{
														width: column.width,
														maxWidth: column.width,
													}}
												>
													{column?.Header && column.render("Header")}

													{filterConfigs && (
														<TableFilter
															{...filterConfigs}
															onChange={(event) => {
																gotoPage?.(FIRST_PAGE);
																filterConfigs.onChange(event);
															}}
														/>
													)}
												</div>
											</th>
										);
									})}
								</tr>
							);
						})}
					</thead>
					<tbody {...getTableBodyProps()} ref={bodyRef}>
						{rows.map((row) => {
							prepareRow(row);

							return (
								<tr
									{...row.getRowProps({
										...getNewRowProps?.(row),
									})}
									style={{ height: bodyRowHeight }}
								>
									{row.cells.map((cell) => {
										return (
											<td
												{...cell.getCellProps()}
												className={classNames({
													"no-gutters": pagination,
												})}
											>
												<div
													style={{
														height: pagination ? "100%" : undefined,
														display: pagination ? "flex" : "block",
														alignItems: pagination ? "center" : undefined,
													}}
												>
													{cell.render("Cell")}
												</div>
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			{(oldPagination || pagination) && (
				<div className="main-table_footer" ref={paginationRef}>
					<div className="pagination">
						<div className="pagination-events">
							<div className="pagination-events_nav">
								<button
									className="pagination-events_nav_first"
									onClick={() => {
										if (oldPagination) {
											first?.clickLink();
										}

										if (pagination) {
											gotoPage(FIRST_PAGE);
											getItemsOnCurrentPage(FIRST_PAGE);
										}
									}}
									disabled={oldPagination ? first?.disabledLink : !canPreviousPage}
									data-testid={TablePaginationImageConstants.FirstImage}
								/>
								<button
									className="pagination-events_nav_prev"
									onClick={() => {
										if (oldPagination) {
											previous?.clickLink();
										}

										if (pagination) {
											previousPage();
											getItemsOnCurrentPage(currentPageIndex - 1);
										}
									}}
									disabled={oldPagination ? previous?.disabledLink : !canPreviousPage}
									data-testid={TablePaginationImageConstants.PrevImage}
								/>
								<span onClick={self?.clickLink}>{oldPagination ? page + 1 : currentPageIndex + 1}</span>
								<button
									className="pagination-events_nav_next"
									onClick={() => {
										if (oldPagination) {
											next?.clickLink();
										}

										if (pagination) {
											nextPage();
											getItemsOnCurrentPage(currentPageIndex + 1);
										}
									}}
									disabled={oldPagination ? next?.disabledLink : !canNextPage}
									data-testid={TablePaginationImageConstants.NextImage}
								/>
								<button
									className="pagination-events_nav_last"
									onClick={() => {
										if (oldPagination) {
											last?.clickLink();
										}

										if (pagination) {
											const LAST_PAGE = pageCount - 1;
											gotoPage(LAST_PAGE);
											getItemsOnCurrentPage(LAST_PAGE);
										}
									}}
									disabled={oldPagination ? last?.disabledLink : !canNextPage}
									data-testid={TablePaginationImageConstants.LastImage}
								/>
							</div>
							<div
								className="pagination-events_pages mr-left cl-fs"
								data-testid={TableConstants.TotalPages}
							>
								Всего страниц: {pageCount}
							</div>
							<div
								className="pagination-events_records mr-left cl-fs"
								data-testid={TableConstants.AllTableItems}
							>
								Всего записей: {totalCount}
							</div>
						</div>
						{paginationContent && <div className="pagination-sub_content">{paginationContent}</div>}
					</div>
				</div>
			)}
		</div>
	);
};
