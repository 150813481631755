import React, { HTMLAttributes } from "react";
import "./InformationBlockWithTooltip.scss";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { SettingsIcon } from "../../Icons/SettingsIcon";
import { WhiteClickedTooltip } from "../../Tooltips/WhiteTooltip/WhiteClickedTooltip";

export interface InformationBlockWithTooltipProps extends HTMLAttributes<HTMLDivElement> {
	label?: string;
	tooltipContent: React.ReactNode | React.ReactNodeArray;
	tooltipContainerStyles?: React.CSSProperties;
	iconTitle: string;
}

export const InformationBlockWithTooltip: React.FC<InformationBlockWithTooltipProps> = ({
	children,
	label,
	tooltipContent,
	tooltipContainerStyles,
	iconTitle,
}) => {
	return (
		<>
			<InformationBlock>
				<div className="information-block__header">
					<div />
					{label && <p className="text-color_dark">{label}</p>}
					<WhiteClickedTooltip
						position="right"
						tooltipContent={tooltipContent}
						tooltipContainerStyles={tooltipContainerStyles}
					>
						{({ onWrapperClicked, innerRef }) => {
							return (
								<div
									className="information-block__settings make-hover"
									ref={innerRef}
									onClick={onWrapperClicked}
									title={iconTitle}
								>
									<SettingsIcon />
								</div>
							);
						}}
					</WhiteClickedTooltip>
				</div>
				<div className="information-block__body">{children}</div>
			</InformationBlock>
		</>
	);
};
