import { ThunkType } from "../index";
import { investigatesActions } from "./index";
import { notifyActions } from "../notify";
import { InvestigateService } from "../../api/investigate";

async function getResponseInvestigate(investigateId: string, interval: NodeJS.Timer, dispatch: any) {
	const response = await InvestigateService.getInvestigateById(investigateId);

	response.map((data) => {
		dispatch(investigatesActions.fetchInvestigateSuccess(data));
		dispatch(notifyActions.setStatusNotify(true));
		clearInterval(interval);
		return data;
	});
}

export function fetchInvestigate(body: FormData): ThunkType {
	return async (dispatch) => {
		dispatch(investigatesActions.fetchInvestigateStart());

		let id = "";

		const response = await InvestigateService.sendInvestigateData(body);

		response
			.map((data) => {
				const { investigateId = id } = data;

				if (investigateId) {
					id = investigateId;
					const interval = setInterval(() => {
						getResponseInvestigate(id, interval, dispatch);
					}, 5000);
				}

				return data;
			})
			.getOrThrow("error", () => {
				dispatch(investigatesActions.fetchInvestigateError("error"));
				dispatch(notifyActions.setStatusNotify(true));
			});
	};
}
