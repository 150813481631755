export const optionsProcess = [
	{
		label: "Расследование",
		value: "INVESTIGATE",
	},
	{
		label: "Уникализация документа",
		value: "UNIQUIZE",
	},
	{
		label: "Загрузка документа",
		value: "UPLOAD",
	},
];

export const optionsStatus = [
	{
		label: "Успешно",
		value: "SUCCESS",
	},
	{
		label: "Ошибка",
		value: "FAILED",
	},
];

export const optionsEvents = [
	{
		label: "Документ был ранее загружен",
		value: "DOCUMENT_ALREADY_UPLOADED",
	},
	{
		label: "Оригинальный документ удалён",
		value: "DOCUMENT_DELETED",
	},
	{
		label: "Документ уникализирован",
		value: "DOCUMENT_UNIQUIZED",
	},
	{
		label: "Документ загружен",
		value: "DOCUMENT_UPLOADED",
	},
	{
		label: "Расследование завершилось с ошибкой",
		value: "INVESTIGATION_FAILED",
	},
	{
		label: "Начато расследование",
		value: "INVESTIGATION_REQUESTED",
	},
	{
		label: "Расследование завершено",
		value: "INVESTIGATION_SUCCESSFUL",
	},
	{
		label: "Скачана страница",
		value: "PAGE_DOWNLOADED",
	},
	{
		label: "Уникальный документ удалён",
		value: "UNIQUE_DOCUMENT_DELETED",
	},
	{
		label: "Уникальный документ скачан",
		value: "UNIQUE_DOCUMENT_DOWNLOADED",
	},
	{
		label: "Уникализация документа",
		value: "UNIQUE_DOCUMENT_REQUESTED",
	},
	{
		label: "Загрузка документа",
		value: "UPLOAD_REQUESTED",
	},
];

export function getNameProcess(term: string): string | undefined {
	return optionsProcess.find((option) => option.value === term)?.label;
}

export function getNameEvent(term: string): string | undefined {
	return optionsEvents.find((option) => option.value === term)?.label;
}

//todo Khloptsev
export function getNameStatus(term: string): string | undefined {
	return optionsStatus.find((option) => option.value === term)?.label;
}

export function currentElementDropdown(
	item: string,
	options: Array<{ value: string; label: string }>
): { value: string; label: string } | undefined {
	return options.find((option) => option.value === item);
}
