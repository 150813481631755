import axios, { AxiosRequestConfig } from "axios";
//import { isDevelopmentMode } from "../helpers/commonHelpers/commonHelpers";

//const basePath = "192.168.60.4";

//const HOSTS = {
//	qa1: `${basePath}6`,
//	qa2: `${basePath}7`,
//	qa3: `${basePath}8`,
//	qa4: `${basePath}9`,
//};

//const URL = isDevelopmentMode() ? HOSTS.qa1 : window.location.hostname;

const URL = window.location.hostname;

export const baseURL = `http://${URL}`;

export const buildBaseApiUr = (path: string): string => {
	return `${baseURL}/web/v1/${path}`;
};

export const axiosInstance = axios.create({
	baseURL: buildBaseApiUr(""),
	withCredentials: true,
});

export const newAxiosInstance = axios.create({
	baseURL: buildBaseApiUr(""),
	withCredentials: true,
});

export const API_PATH = {
	license: {
		getLicense: "license",
		sendLicense: "license",
	},
	jobs: {
		getJobs: "events",
		exportFile: `events/export`,
	},
	documents: {
		getDocuments: "documents",
	},
	investigates: {
		getInvestigates: `investigates`,
		getInvestigateById: (id: string) => `investigates/${id}`,
		sendInvestigateData: `investigates`,
	},
	contacts: {
		getContacts: "contacts.json",
		getReleaseNotes: "RELEASE.md",
	},
	settings: {
		notificationSettings: "settings/notification-settings",
	},
	externalSystems: {
		externalSystems: "charts/externalSystems",
		documentsUploadedVolume: "charts/documentsUploadedVolume",
	},
} as const;

export const sendWithToken = (params: AxiosRequestConfig): AxiosRequestConfig => {
	const token = window?.keycloak?.token ?? "";

	return {
		...params,
		headers: { ...params.headers, Authorization: `Bearer ${token}` },
	};
};

axiosInstance?.interceptors?.request?.use(sendWithToken);
newAxiosInstance?.interceptors?.request?.use(sendWithToken);
