import React from "react";
import Select from "react-select";
import { NamedProps } from "react-select/src/Select";
import { DropdownConstants } from "./constants";
import classNames from "classnames";
import { StylesConfig } from "react-select/src/styles";
import { DropdownIndicator } from "./DropdownIndicator";
import { CSSObject } from "@emotion/serialize";

interface Props {
	options?: NamedProps["options"];
	handleChange?: NamedProps["onChange"];
	label: string;
	style?: React.CSSProperties;
	disabled?: boolean;
	defaultValue: NamedProps["defaultValue"];
	onFocus?: NamedProps["onFocus"];
	onBlur?: NamedProps["onBlur"];
	onChange?: NamedProps["onChange"];
	id?: string;
	isSearchable?: boolean;
	controlStyle?: CSSObject;
}

export const Dropdown: React.FC<Props> = ({
	options = [],
	handleChange,
	label,
	style = {},
	disabled,
	defaultValue,
	onFocus,
	onBlur,
	id,
	isSearchable = true,
	controlStyle = {},
}) => {
	const [isActive, setIsActive] = React.useState(false);
	const setActiveTrue = () => setIsActive(true);
	const setActiveFalse = () => setIsActive(false);
	const activeColor = "#C0D9FF";
	const baseColor = "#adb3bb";
	const activeBorder = `1px solid ${activeColor}`;
	const activeBoxShadow = `0 0 0 1px ${activeColor}`;

	const customStyles: StylesConfig<Array<{ value: string; label: string }>, false> = {
		control: (provided, { isFocused, menuIsOpen }) => {
			const isActive = menuIsOpen || isFocused;
			return {
				...provided,
				cursor: "pointer",
				minHeight: "42px",
				border: isActive ? activeBorder : "1px solid #adb3bb",
				boxShadow: isActive ? activeBoxShadow : undefined,
				padding: "7px 12px 6px 15px ",
				...controlStyle,
				":hover": {
					...provided[":hover"],
					borderColor: isActive ? activeColor : baseColor,
				},
			};
		},
		valueContainer: (provided) => {
			return { ...provided, padding: "0" };
		},
		placeholder: (provided) => {
			return { ...provided, margin: "0" };
		},

		option: (provided, state) => {
			const isActive = state.isSelected;
			const activeColor = "#50749d";
			const backgroundColor = "#fff";

			const color = isActive ? activeColor : "#222";

			return {
				...provided,
				color,
				backgroundColor: "#fff",
				cursor: "pointer",
				":active": {
					...provided[":active"],
					backgroundColor,
					color: activeColor,
				},
				":hover": {
					...provided[":hover"],
					backgroundColor,
					color: activeColor,
					borderColor: "red",
				},
			};
		},
	};

	return (
		<div
			className={classNames("dropdown", {
				active: isActive,
			})}
			style={style}
			data-testid={DropdownConstants.Container}
		>
			<Select
				/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
				// @ts-ignore
				styles={customStyles}
				id={id}
				className="basic-multi-select"
				options={options}
				defaultValue={defaultValue}
				placeholder={label}
				isSearchable={isSearchable}
				onChange={handleChange}
				isDisabled={disabled}
				components={{
					DropdownIndicator: !disabled ? DropdownIndicator : null,
				}}
				onFocus={(event) => {
					setActiveTrue();
					onFocus?.(event);
				}}
				onBlur={(event) => {
					setActiveFalse();
					onBlur?.(event);
				}}
				theme={(theme) => ({
					...theme,
					borderRadius: 3,
					colors: {
						...theme.colors,
						primary25: "#f1e8e8",
						primary: "#adb3bb",
					},
				})}
			/>
		</div>
	);
};
