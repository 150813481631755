import { ThunkType } from "../index";
import { getDocumentsUploadedVolume, getExternalSystemList } from "../../api/externalSystems";
import { externalSystemsActions } from "./index";
import { DocumentsUploadedVolumeRequest } from "../../models/externalSystem/request";

export const getDocumentsUploadedVolumeAsync = (queries: DocumentsUploadedVolumeRequest): ThunkType => {
	return async (dispatch) => {
		const response = await getDocumentsUploadedVolume(queries);

		response.map(({ data }) => {
			dispatch(externalSystemsActions.getDocumentsUploadedVolume(data));
		});
	};
};

export const getExternalSystemListAsync = (): ThunkType => {
	return async (dispatch) => {
		const response = await getExternalSystemList();
		response.map(({ data }) => {
			dispatch(externalSystemsActions.getExternalSystemList(data));
		});
	};
};
