import { Jobs } from "../models/jobs/jobs";
import { API_PATH, newAxiosInstance } from "./configs";
import { jobsSettings } from "../constants/common";
import { IJobsResponse } from "../models/jobs/jobsResponse";
import { removeEmptyFieldsInObject } from "../helpers/commonHelpers/commonHelpers";
import { fromJSDateToServerTime } from "../helpers/dates/dates";
import { OldEitherResponse } from "../models/common";
import { Api } from "./api";

export class JobsService {
	private static convertJobsResponse(params: IJobsResponse): Partial<IJobsResponse> {
		const checkDate = (date: Date | null): string => {
			if (date !== null) {
				return fromJSDateToServerTime(date);
			}
			return "";
		};

		const newParams = {
			...params,
			dateStart: checkDate(params.dateStart),
			dateEnd: checkDate(params.dateEnd),
		};

		return removeEmptyFieldsInObject(newParams) as Partial<IJobsResponse>;
	}

	public static async getJobs(query: string, params: IJobsResponse): Promise<OldEitherResponse<Jobs>> {
		const convertQuery = query.replace("/web/v1/events", "");

		const getFilterParams = this.convertJobsResponse(params);

		return await new Api().oldGet<Jobs>(`/${API_PATH.jobs.getJobs}${convertQuery}`, {
			params: { ...getFilterParams, perPage: jobsSettings.jobsCount },
			url: query,
		});
	}

	/**
	 * Работает только на стенде т.к. путь генерируется по текущему url, который локально localhost.
	 */
	public static async getFile(params: IJobsResponse): Promise<void> {
		const getFilterParams = this.convertJobsResponse(params);
		newAxiosInstance
			.get(`${API_PATH.jobs.exportFile}`, {
				responseType: "arraybuffer",
				headers: {
					"Content-Type": "application/octet-stream",
				},
				params: getFilterParams,
			})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "report.xlsx");
				document.body.appendChild(link);
				link.click();
				link.remove();
			});
	}
}
