import React from "react";
import "./RadioList.scss";
import { IRadioButtonData, RadioButton } from "./RadioButton/RadioButton";

export type RadioButtonList = ReadonlyArray<IRadioButtonData>;

export interface RadioListProps {
	data: RadioButtonList;
	onSelect?: (targetList: RadioButtonList) => void;
}

const FIRST_ELEMENT = 0;
const IS_EMPTY = 0;

export const RadioList: React.FC<RadioListProps> = ({ data = [], onSelect }) => {
	const hasSelectChangedRef = React.useRef<boolean>(false);
	const [list, setList] = React.useState<RadioButtonList>([]);
	const [selectIndex, setSelectIndex] = React.useState<number | null>(null);

	const onChange = ({
		target,
		index,
		currentList = data,
	}: {
		target: IRadioButtonData;
		index: number;
		currentList: RadioButtonList;
	}): void => {
		const newList = currentList.map((x) => {
			return {
				...x,
				checked: x.name === target.name,
			};
		});

		setSelectIndex(index);
		setList(newList);
		if (hasSelectChangedRef.current) {
			onSelect?.(newList);
		}
	};

	React.useEffect(() => {
		const candidateIndex = data.findIndex((x) => x.checked);

		const hasSelected = candidateIndex > FIRST_ELEMENT;

		if (hasSelected) {
			setSelectIndex(candidateIndex);
			setList(data);
		}

		if (!hasSelected && data.length > IS_EMPTY) {
			onChange({
				target: data[FIRST_ELEMENT],
				index: FIRST_ELEMENT,
				currentList: data,
			});
		}

		if (!hasSelectChangedRef.current) {
			hasSelectChangedRef.current = true;
		}
	}, []);

	return (
		<div className="radiobutton-list__wrapper">
			{list.map((x, index) => {
				const isItemSelect = selectIndex === index;

				return (
					<RadioButton
						key={x.name}
						label={x.name}
						checked={isItemSelect}
						onChange={() => {
							if (!isItemSelect) {
								onChange({
									target: x,
									index,
									currentList: list,
								});
							}
						}}
						id={x.name}
						name={x.name}
					/>
				);
			})}
		</div>
	);
};
