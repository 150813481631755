export const appRoutes = {
	investigation: "investigation",
	story: "story",
	journal: "journal",
	about: "about",
	release: "release",
	settings: "notifications-settings",
	report: "report",
} as const;

export const CURRENT_PAGE = "current_page";
