import { ExternalSystemChart, IExternalSystemList } from "../../models/externalSystem/response";

export interface IExternalSystemsInitialState {
	documentsUploadedVolume: ExternalSystemChart;
	externalSystemsList: IExternalSystemList;
}

export const externalSystemsInitialState: IExternalSystemsInitialState = {
	documentsUploadedVolume: {
		externalSystems: {},
		total: {
			documentsCount: 0,
			documentsSize: 0,
			documentsMaxCount: 0,
		},
	},
	externalSystemsList: [],
};
