import React from "react";
import { Dropdown } from "../../../UI/Dropdown/Dropdown";
import classNames from "classnames";
import { NamedProps } from "react-select/src/Select";
import { NameValueType } from "../../../../models/common";
import { CSSObject } from "@emotion/serialize";
import { useBoolean } from "../../../../hooks/useBoolean";

interface InputForm {
	label: string;
	defaultValue: NamedProps["defaultValue"];
	id: string;
	handleChange?: ({ name, value }: NameValueType) => void;
	options: NamedProps["options"];
	name?: string;
	controlStyle?: CSSObject;
}

export const DropdownForm: React.FC<InputForm> = (props) => {
	const { label, defaultValue, id, handleChange, options, name, controlStyle } = props;
	const { value: isActive, setTrue: setActiveTrue, setFalse: setActiveFalse } = useBoolean();

	return (
		<div className="dropdown-text__wrapper">
			<label
				htmlFor={id}
				className={classNames("label-dropdown", {
					"label-inside": !isActive || !defaultValue,
					"label-outside": isActive || !!defaultValue,
				})}
			>
				{label}
			</label>
			<div id={id}>
				<Dropdown
					id={id}
					options={options}
					handleChange={(data) => {
						handleChange?.({
							name: name ?? "",
							value: data?.value ?? "",
						});
					}}
					isSearchable={false}
					defaultValue={defaultValue}
					label=""
					style={{ width: "361px" }}
					onFocus={setActiveTrue}
					onBlur={setActiveFalse}
					controlStyle={controlStyle}
				/>
			</div>
		</div>
	);
};
