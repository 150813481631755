import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, TableButtonType } from "../../components/Table/Table";
import { fetchJobs } from "../../store/jobs/operations";
import allData from "../../assets/allData/allData.svg";
import { Modal } from "../../components/Modals/Modal/Modal";
import { TooltipTableJob } from "../../components/Tooltips/TooltipTableJob/TooltipTableJob";
import { selectMetadata, selectRecords } from "../../store/jobs/jobsSelectors";
import { JobsRecords, JobsRecordsEventData } from "../../models/jobs/jobs";
import { Chip } from "../../components/Chip/Chip";
import { getNameProcess, getNameStatus } from "./optionsDropdown/optionsDropdown";
import { HiddenParametersForm } from "../../components/Forms/ParametersForm/HiddenParametersForm/HiddenParametersForm";
import { StatusJob } from "../../constants/status";
import { jobsSettings } from "../../constants/common";
import { useBoolean } from "../../hooks/useBoolean";
import { IJobsResponse } from "../../models/jobs/jobsResponse";
import { DropdownIcon } from "../../components/UI/Icons/DropdownIcon";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { localStorageKeyList } from "../../constants/storage";
import { convertDateFromDateJS, formatDateTime } from "../../helpers/dates/dates";
import { WarningIcon } from "../../components/UI/Icons/WarningIcon";
import { HeaderGroupsType } from "../../components/Table/Table.types";
import { SvgContainer } from "../../components/Icons/SvgContainer";
import { JobsService } from "../../api/jobs";

interface IParametersData {
	idDoc: string;
	eventType: string;
	status: string;
	fileName: string;
	externalSystemId: string;
	date: string;
	userId: string;
}

interface IParametersDate {
	dateStart: Date | null;
	dateEnd: Date | null;
}

// eslint-disable-next-line no-empty-pattern
type IChangeParametersDataHandle<T> = ({}: { label: keyof T; value: T[keyof T]; mustBeSend?: boolean }) => T;

// eslint-disable-next-line no-empty-pattern
type IChangeParametersDateHandle<T> = (
	{}: { label: keyof T; value: T[keyof T]; mustSendResult?: boolean },
	mustBeSend: boolean
) => void;

type FormatJobsRecords = Omit<JobsRecords, "eventData"> & {
	eventData: JobsRecordsEventData[];
};

export interface IDataParameters {
	parametersData: IParametersData;
	parametersDate: IParametersDate;
	changeParametersDataHandle: IChangeParametersDataHandle<IParametersData>;
	changeParametersDateHandle: IChangeParametersDateHandle<IParametersDate>;
	sendResult: () => void;
}

const getDefaultParametersData = (): IParametersData => ({
	externalSystemId: "",
	eventType: "",
	idDoc: "",
	status: "",
	fileName: "",
	date: "",
	userId: "",
});

const getDefaultParametersDate = (): IParametersDate => ({
	dateEnd: null,
	dateStart: null,
});

const DATE_CHIP_LABEL: keyof IParametersData = "date";

export const Journal: React.FC = () => {
	const dispatch = useDispatch();
	const [eventData, setEventData] = useState<JobsRecords["eventData"] | null>(null);

	const { storedValue: parametersStorageData, setValue: setParametersStorageData } = useLocalStorage(
		localStorageKeyList.parametersData,
		getDefaultParametersData()
	);
	const [parametersDate, setParametersDate] = useState<IParametersDate>(getDefaultParametersDate());
	const [parametersData, setParametersData] = useState<IParametersData>(parametersStorageData);

	const { setFalse: hideTooltip, value: tooltipActive, setTrue: openTooltip } = useBoolean();
	const { value: modalActive, setValue: setModalActive } = useBoolean();

	const records = useSelector(selectRecords);
	const metadata = useSelector(selectMetadata);

	const { date, ...newParametersData } = parametersData;
	const result: IJobsResponse = { ...newParametersData, ...parametersDate };

	const sendResult = (target?: IJobsResponse): void => {
		dispatch(fetchJobs({ data: target ?? result }));
		if (!target) {
			setParametersStorageData(parametersData);
		}
		hideTooltip();
	};

	useEffect(() => {
		sendResult();
	}, []);

	const changeParametersData: IChangeParametersDataHandle<IParametersData> = ({ label, value }): IParametersData => {
		let data: IParametersData | undefined;
		setParametersData((x) => {
			const result = {
				...x,
				[label]: value.trim(),
			};
			data = result;
			return result;
		});
		return data!;
	};

	const changeParametersDate: IChangeParametersDateHandle<IParametersDate> = ({ label, value }, mustSendResult) => {
		setParametersDate((x) => {
			const newParameters = {
				...x,
				[label]: value,
			};

			const isNotEmpty = Object.values(newParameters).every((x) => !!x);

			const dataResult = changeParametersData({
				label: "date",
				value: isNotEmpty
					? `от ${convertDateFromDateJS(newParameters.dateStart!)} до ${convertDateFromDateJS(
							newParameters.dateEnd!
					  )}`
					: "",
			});

			if (mustSendResult) {
				const convertData = { ...dataResult, ...newParameters };
				sendResult(convertData);
				setParametersStorageData(dataResult);
			}

			return newParameters;
		});
	};

	const formatRecords = (records: JobsRecords[] = []): FormatJobsRecords[] => {
		return records.map((item) => {
			return {
				...item,
				eventDate: formatDateTime(item.eventDate),
				eventData: [item.eventData],
			};
		});
	};

	const getCurrentEventData = (id: number) => {
		setEventData(records[id].eventData);
	};

	const changePage = (query: string) => {
		dispatch(fetchJobs({ query, data: result }));
	};

	const getObgLink = (link = ""): TableButtonType => {
		return {
			clickLink: () => {
				changePage(link);
			},
			disabledLink: !link,
		};
	};

	const formatChipLabel = (label: string, value: string): string => {
		const chipCustomLabel: { [key: string]: string | undefined } = {
			eventType: getNameProcess(value),
			status: getNameStatus(value),
		};

		return chipCustomLabel[label] ?? value;
	};

	const onChipClose = (parameterLabel: keyof IParametersData): void => {
		setParametersData((y) => {
			const newParameters = {
				...y,
				[parameterLabel]: "",
			};

			const isDateParameters = parameterLabel === DATE_CHIP_LABEL;

			const checkCurrentParameters = isDateParameters ? getDefaultParametersDate() : parametersDate;

			if (isDateParameters) {
				setParametersDate(getDefaultParametersDate());
			}

			dispatch(
				fetchJobs({
					data: {
						...newParameters,
						...checkCurrentParameters,
					},
				})
			);

			setParametersStorageData(newParameters);
			return newParameters;
		});
	};

	const columns: HeaderGroupsType<FormatJobsRecords> = [
		{
			Header: "Info",
			columns: [
				{
					Header: "Дата события",
					accessor: "eventDate",
				},
				{
					Header: "Событие",
					accessor: "eventType",
				},
				{
					Header: "Наименование документа",
					accessor: ({ fileName }) => {
						return (
							<span className="short-text-1" title={fileName}>
								{fileName}
							</span>
						);
					},
				},

				{
					Header: "",
					id: "eventData",
					accessor: (data, index) => {
						const checkTitle = ({ eventType = "" }): string => {
							let key = "";
							const map = new Map<string, string>([
								["Расследование", "Ошибка расследования"],
								["Загрузка документа", "Документ был ранее загружен"],
							]);

							if (map.has(eventType)) {
								key = map.get(eventType) as string;
							}

							return key;
						};
						return (
							<div style={{ display: "flex", justifyContent: "flex-end", marginRight: "15px" }}>
								{data.status === StatusJob.FAILED && (
									<WarningIcon style={{ marginRight: "40px" }} title={checkTitle(data)} />
								)}
								<img
									style={{ cursor: "pointer" }}
									src={allData}
									title="Сведения"
									onClick={() => {
										getCurrentEventData(index as number);
										setModalActive(true);
									}}
								/>
							</div>
						);
					},
				},
			],
		},
	];

	const exportFile = async (): Promise<void> => {
		JobsService.getFile(result);
	};

	return (
		<div className="container job">
			<div
				className="filter-params"
				style={{
					zIndex: tooltipActive ? 300 : undefined,
				}}
			>
				<div className="filter-params_event" onClick={openTooltip}>
					<div className="make-hover" style={{ display: "flex", alignItems: "center" }}>
						<span className="filter-params_event-label">Параметры</span>
						<div className="icon-wrapper">
							<DropdownIcon />
						</div>
					</div>
					{tooltipActive && (
						<HiddenParametersForm
							onClickOutlined={hideTooltip}
							data={{
								changeParametersDateHandle: changeParametersDate,
								changeParametersDataHandle: changeParametersData,
								parametersData,
								parametersDate,
								sendResult,
							}}
						/>
					)}
				</div>
				<div className="chips">
					{Object.keys(parametersStorageData).map((x, index) => {
						const parameterKey = x as keyof IParametersData;
						const parameterValue = parametersStorageData[parameterKey] ?? "";

						const chip = (
							<Chip key={`${parameterValue}-${index}`} click={() => onChipClose(parameterKey)}>
								{formatChipLabel(x, parameterValue)}
							</Chip>
						);

						return parameterValue && chip;
					})}
				</div>
			</div>

			{metadata && records && (
				<>
					<Table
						columns={columns}
						data={formatRecords(records)}
						oldPagination={{
							self: getObgLink(metadata.links.self),
							first: getObgLink(metadata.links.first),
							previous: getObgLink(metadata.links.previous),
							next: getObgLink(metadata.links.next),
							last: getObgLink(metadata.links.last),
							totalCount: metadata.totalCount,
							pageCount: metadata.pageCount,
							itemCount: jobsSettings.jobsCount,
							page: metadata.page,
						}}
						paginationContent={
							<>
								Экспортировать таблицу:{" "}
								<SvgContainer icon="excel" title="" hasHover onClick={exportFile} />
							</>
						}
					/>
					<Modal label="Сведения" active={modalActive} setActive={setModalActive}>
						<TooltipTableJob data={eventData} />
					</Modal>
				</>
			)}
		</div>
	);
};
