import { InvestigatesType } from "../../models/investigates/Investigates";
import { StoryInvestigatesInvestigates } from "../../models/story/StoryInvestigates";
import { Pagination } from "../../models/common";

export interface StoryInitialStateType {
	loading: boolean;
	error: string | null;
	investigates: StoryInvestigatesInvestigates[];
	investigatesMetadata?: Pagination;
	selectedInvestigate: InvestigatesType | null;
}

export const storyInitialState: StoryInitialStateType = {
	loading: false,
	error: null,
	investigates: [],
	selectedInvestigate: null,
	investigatesMetadata: undefined,
};
