import React from "react";
import classNames from "classnames";

interface Props {
	leftPart: string | React.ReactNode;
	rightPart: string | React.ReactNode;
	rowParentClass: "table__width-number__column-row" | "table__width-parts__column-row";
}

export const TablePartRow: React.FC<Props> = ({ leftPart, rightPart, rowParentClass }: Props) => {
	const isString = (title: string | React.ReactNode): string | undefined => {
		return typeof title === "string" ? title : undefined;
	};
	return (
		<div
			className={classNames("table__column-row", {
				[rowParentClass]: rowParentClass,
			})}
		>
			<div className="table__width-number__item table__width-number__item-left">
				<span title={isString(leftPart)}>{leftPart}</span>
			</div>
			<div className="table__width-number__item table__width-number__item-right">
				<span title={isString(rightPart)}>{rightPart}</span>
			</div>
		</div>
	);
};
