import React from "react";
import { ColorBoxConstants } from "./ColorBox.constants";

export interface ColorBoxProps {
	height?: string;
	width?: string;
	backgroundColor?: string;
}

const DEFAULT_SIZE = "22px";

export const ColorBox: React.FC<ColorBoxProps> = ({ height = DEFAULT_SIZE, width = DEFAULT_SIZE, backgroundColor }) => {
	return (
		<>
			<div
				data-testid={ColorBoxConstants.Container}
				className="color-box"
				data-color={backgroundColor}
				style={{
					height,
					width,
					minHeight: height,
					minWidth: width,
					backgroundColor,
					borderRadius: "4px",
				}}
			/>
		</>
	);
};
