import { NewRootState } from "../index";
import { createSelector } from "@reduxjs/toolkit";

const select = (state: NewRootState) => state.externalSystems;

export const selectExternalSystemsList = createSelector(select, (systems) => {
	return systems.externalSystemsList;
});

export const selectDocumentsUploadedVolume = createSelector(select, (systems) => {
	return systems.documentsUploadedVolume;
});
