import { AxiosInstance, AxiosRequestConfig } from "axios";
import { axiosInstance, newAxiosInstance } from "./configs";
import { EitherResponse, OldEitherResponse, OldResponseType, ResponseStandard, ResponseType } from "../models/common";
import { Either } from "../helpers/errors/errors";
import { toast } from "react-toastify";
import { ToastOptions } from "react-toastify/dist/types";

const toastOptions: ToastOptions = {
	autoClose: 3000,
	hideProgressBar: true,
};

export class Api {
	private readonly request: AxiosInstance;
	private readonly oldRequest: AxiosInstance;
	private readonly toastOptions: ToastOptions;

	constructor() {
		this.request = newAxiosInstance;
		this.oldRequest = axiosInstance;
		this.toastOptions = toastOptions;
	}

	private sendSuccessResponse = <T>(target: ResponseType<T>): EitherResponse<T> => {
		const message = target.data?.message ?? "";

		if (message) {
			toast.success(message, this.toastOptions);
		}

		return Either.right(target.data);
	};

	private sendErrorResponse = <T>(target?: ResponseType<T>, message = ""): EitherResponse<T> => {
		if (message) {
			toast.error(message, this.toastOptions);
		}

		return Either.left(message);
	};

	public async get<T>(url: string, config?: AxiosRequestConfig): Promise<EitherResponse<T>> {
		try {
			const response = await this.request.get<ResponseStandard<T>>(url, config);
			return this.sendSuccessResponse(response);
		} catch (e) {
			const message = e.response?.data?.message ?? "";

			return this.sendErrorResponse(e.response, message);
		}
	}

	public async post<D, R>(url: string, data?: D, config?: AxiosRequestConfig): Promise<EitherResponse<R>> {
		try {
			const response = await this.request.post<ResponseStandard<R>>(url, data, config);

			return this.sendSuccessResponse(response);
		} catch (e) {
			const message = e.response.data.message;

			return this.sendErrorResponse(e.response, message);
		}
	}

	public async put<D, R>(url: string, data?: D, config?: AxiosRequestConfig): Promise<EitherResponse<R>> {
		try {
			const response = await this.request.put<ResponseStandard<R>>(url, data, config);

			return this.sendSuccessResponse(response);
		} catch (e) {
			const message = e.response.data.message;

			return this.sendErrorResponse(e.response, message);
		}
	}

	/**
	 * @deprecated
	 */
	private oldSendSuccessResponse = <T>(target: OldResponseType<T>): OldEitherResponse<T> => {
		return Either.right(target.data);
	};

	/**
	 * @deprecated
	 */
	private oldSendErrorResponse = <T>(target?: OldResponseType<T>, message = ""): OldEitherResponse<T> => {
		return Either.left(message);
	};

	/**
	 * @deprecated
	 */
	public async oldGet<T>(url: string, config?: AxiosRequestConfig): Promise<OldEitherResponse<T>> {
		try {
			const response = await this.oldRequest.get<T>(url, config);

			return this.oldSendSuccessResponse(response);
		} catch (e) {
			const message = e.response?.data?.message ?? "";

			return this.oldSendErrorResponse(e.response, message);
		}
	}

	/**
	 * @deprecated
	 */
	public async oldPost<D, R>(url: string, data?: D, config?: AxiosRequestConfig): Promise<OldEitherResponse<R>> {
		try {
			const response = await this.oldRequest.post<R>(url, data, config);

			return this.oldSendSuccessResponse(response);
		} catch (e) {
			const message = e.response?.data?.message ?? "";

			return this.oldSendErrorResponse(e.response, message);
		}
	}
}
