import React from "react";
import "./Header.scss";
import { useLocation } from "react-router-dom";
import Avatar from "../../assets/avatar/avatar.svg";
import iconSetting from "../../assets/iconSetting/iconSetting.svg";
import { SubMenu } from "../SubMenu/SubMenu";
import { HeaderConstants } from "./constants";
import { useBoolean } from "../../hooks/useBoolean";
import { useComponentVisible } from "../../hooks/useComponentVisible";
import classNames from "classnames";
import { appRoutes } from "../../constants/routes";

export interface HeaderRoutes {
	[appRoutes.story]: "История расследований";
	[appRoutes.investigation]: "Расследование";
	[appRoutes.journal]: "Журнал";
	[appRoutes.release]: "Примечания к выпуску";
	[appRoutes.about]: "О программе";
	[appRoutes.settings]: "О программе";
	[appRoutes.report]: "Графики загруженных документов";
}

export const headerRoutes: { [key: string]: string } = {
	[appRoutes.story]: "История расследований",
	[appRoutes.investigation]: "Расследование",
	[appRoutes.journal]: "Журнал событий",
	[appRoutes.about]: "О программе",
	[appRoutes.release]: "Примечания к выпуску",
	[appRoutes.settings]: "Настройки уведомлений",
	[appRoutes.report]: "Графики загруженных документов",
};

export const Header: React.FC = () => {
	const location = useLocation();
	const { value: active, setFalse, setToggle } = useBoolean();
	const { ref } = useComponentVisible(setFalse);

	const onExit = (): void => {
		window.keycloak.logout();
	};

	return (
		<div
			className="main-header"
			style={{
				zIndex: active ? 100 : undefined,
			}}
		>
			<div className="breadcrumb" data-testid={HeaderConstants.Breadcrumb}>
				{headerRoutes[location.pathname.trim().slice(1)]}
			</div>
			<div className="avatar">
				{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
				{/*@ts-ignore*/}
				<span data-testid={HeaderConstants.Username}>{window.keycloak.idTokenParsed.preferred_username}</span>
				<img src={Avatar} alt="avatar" />
				<span
					ref={ref}
					className={classNames("settings", {
						active,
					})}
					onClick={setToggle}
				>
					<span className="make-hover">
						<img style={{ cursor: "pointer", marginLeft: "15px" }} src={iconSetting} />
					</span>
					<SubMenu onExit={onExit} />
				</span>
			</div>
		</div>
	);
};
