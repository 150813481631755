import React from "react";
import { FilterForm } from "../FilterForm/FilterForm";
import { useComponentVisible } from "../../../../hooks/useComponentVisible";
import { IDataParameters } from "../../../../containers/Journal/Journal";

interface Props {
	onClickOutlined: () => void;
	data: IDataParameters;
}

export const HiddenParametersForm: React.FC<Props> = ({ onClickOutlined, data }) => {
	const { ref } = useComponentVisible(onClickOutlined);
	return (
		<div ref={ref} className="job-tooltip-container">
			<div className="job-tooltip-inner">
				<div className="arrow-container">
					<div className="arrow arrow-bottom"> </div>
				</div>
				<div className="job-tooltip">
					<FilterForm data={data} />
				</div>
			</div>
		</div>
	);
};
