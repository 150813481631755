import { ThunkType } from "../index";
import { jobsActions } from "./index";
import { JobsService } from "../../api/jobs";
import { IJobsResponse } from "../../models/jobs/jobsResponse";

export function fetchJobs({ query = "", data }: { query?: string; data: IJobsResponse }): ThunkType {
	return async (dispatch) => {
		dispatch(jobsActions.fetchJobsStart());
		try {
			const response = await JobsService.getJobs(query, data);

			response.map((value) => {
				dispatch(jobsActions.fetchJobsSuccess(value));
			});
		} catch (e) {
			dispatch(jobsActions.fetchJobsError(e));
		}
	};
}
