import React from "react";
import "./Tooltip.scss";
import classNames from "classnames";

import { TooltipConstants } from "./constants";

interface Props {
	position?: "right";
	tooltipContent: React.ReactNode | React.ReactNodeArray;
}

export const Tooltip: React.FC<Props> = ({ children, position = "right", tooltipContent }) => {
	return (
		<>
			<div className="tooltip-wrapper">
				{children}
				<div
					className={classNames("tooltip", {
						[position]: position,
					})}
					data-testid={TooltipConstants.Container}
				>
					{tooltipContent}
				</div>
			</div>
		</>
	);
};
