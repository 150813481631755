import React from "react";
import "./License.scss";
import { LicenseCard } from "./LicenseCard/LicenseCard";
import { ILicense } from "../../models/license/license";
import { LicenseService } from "../../api/license";
import { Button } from "../UI/Button/Button";
import { LicenseConstants } from "./constants";
import { toast } from "react-toastify";

export const License: React.FC = React.memo(() => {
	const [license, setLicense] = React.useState<ILicense | null>(null);
	const inputRef = React.useRef<HTMLInputElement>(null);

	const downloadLicense = async (): Promise<void> => {
		const response = await LicenseService.getLicense();

		response.map((value) => {
			setLicense(value?.data ?? null);
			return value;
		});
	};

	React.useEffect(() => {
		downloadLicense();
	}, []);

	const downloadButtonHandle = React.useCallback((): void => {
		inputRef.current?.focus();
		inputRef.current?.click();
	}, []);

	const selectFileHandle = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		const body = new FormData();
		const file = event.target?.files?.[0];

		try {
			if (file) {
				body.append("license", file);
				await LicenseService.sendLicenseFile(body);

				downloadLicense();
			}
		} catch (e) {
			toast.error(e.message, {
				autoClose: 3000,
				hideProgressBar: true,
			});
		}
	};

	return (
		<div className="license-container">
			{license && <LicenseCard data={license} />}
			<input type="file" data-testid={LicenseConstants.File} ref={inputRef} onChange={selectFileHandle} hidden />

			<div className="btn-wrapper">
				<Button onClick={downloadButtonHandle} className="primary">
					Загрузить лицензию
				</Button>
			</div>
		</div>
	);
});
