import React, { HTMLAttributes } from "react";
import classNames from "classnames";
import "./ScrollBox.scss";

export interface ScrollBoxProps extends HTMLAttributes<HTMLDivElement> {
	ref?: React.MutableRefObject<HTMLDivElement | null>;
}

export const ScrollBox: React.FC<ScrollBoxProps> = ({ className = "", children, ...props }) => {
	return (
		<div
			{...props}
			className={classNames("scroll-box", {
				[className]: className,
			})}
		>
			{children}
		</div>
	);
};
