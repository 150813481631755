import React from "react";
import { localStorageKeyList } from "../constants/storage";

type StorageKeyofType = typeof localStorageKeyList[keyof typeof localStorageKeyList];

interface IUseLocalStorageReturn<T> {
	storedValue: T;
	setValue: (value: T | ((val: T) => T)) => void;
}

export function useLocalStorage<T>(key: StorageKeyofType, initialValue: T): IUseLocalStorageReturn<T> {
	const [storedValue, setStoredValue] = React.useState<T>(() => {
		try {
			const item = window.localStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			console.log(error);
			return initialValue;
		}
	});

	const setValue = (value: T | ((val: T) => T)): void => {
		try {
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			setStoredValue(valueToStore);
			window.localStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (error) {
			console.log(error);
		}
	};

	return { storedValue, setValue };
}
