import React, { HTMLAttributes } from "react";
import "./InformationBlock.scss";
import classNames from "classnames";

export type InformationBlockProps = HTMLAttributes<HTMLDivElement>;

export const InformationBlock: React.FC<InformationBlockProps> = ({ children, className = "", ...props }) => {
	return (
		<div
			{...props}
			className={classNames("information-block", {
				[className]: className,
			})}
		>
			{children}
		</div>
	);
};
