import { DocumentsType } from "../models/documents/documents";
import { API_PATH } from "./configs";
import { OldEitherResponse } from "../models/common";
import { Api } from "./api";

export class DocumentsService {
	public static async getDocuments(): Promise<
		OldEitherResponse<{
			documents: DocumentsType[];
		}>
	> {
		return await new Api().oldGet<{
			documents: DocumentsType[];
		}>(`/${API_PATH.documents.getDocuments}`);
	}
}
