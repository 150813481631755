import React from "react";

export const CloseIcon: React.FC = () => {
	return (
		<>
			<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M5.64724 16.3497L10.9898 11L5.64724 5.65031"
					stroke="#7294BB"
					strokeWidth="1.875"
					strokeLinecap="round"
				/>
				<path
					d="M16.3367 5.6525L10.9928 11L16.3367 16.3475"
					stroke="#7294BB"
					strokeWidth="1.875"
					strokeLinecap="round"
				/>
			</svg>
		</>
	);
};
