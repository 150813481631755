import React from "react";
import { DropdownIcon } from "../Icons/DropdownIcon";

export const DropdownIndicator: React.FC = () => {
	return (
		<div
			style={{
				height: "100%",
				display: "flex",
				alignItems: "center",
			}}
		>
			<DropdownIcon />
		</div>
	);
};
