import React from "react";
import iconExit from "../../assets/iconExit/iconExit.svg";
import { SubMenuConstants } from "./constants";

interface Props {
	onExit: () => void;
}

export const SubMenu: React.FC<Props> = ({ onExit }) => {
	return (
		<div className="sub-menu">
			<span className="triangle" />
			<div className="sub-menu-wrapper">
				<span className="sub-menu-content" onClick={onExit} data-testid={SubMenuConstants.Button}>
					<img src={iconExit} />
					Выйти
				</span>
			</div>
		</div>
	);
};
