import React from "react";
import "./LicenseCard.scss";
import { InformationBlock } from "../../InformationBlock/InformationBlock";
import { ILicense } from "../../../models/license/license";
import { LicenseCardConstants } from "./constants";
import { formatDateTime } from "../../../helpers/dates/dates";

export interface LicenseCardProps {
	data: ILicense | null;
}

const DATE_FIELD = "Срок действия лицензии";

export const LicenseCard: React.FC<LicenseCardProps> = ({ data }) => {
	const { licenseInfo = [], connectors = [] } = data || {};
	return (
		<InformationBlock>
			<div className="license-card text-color_dark-light">
				<p>Информация о лицензии:</p>
				<ul>
					{licenseInfo.map((x) => {
						return (
							<li key={x.name} data-testid={LicenseCardConstants.LicenseInfoItem}>
								{x.name}:{" "}
								<span className="text-color_dark">
									{x.name === DATE_FIELD ? formatDateTime(x.value) : x.value}
								</span>
							</li>
						);
					})}
				</ul>
				<span>Коннекторы:</span>
				<ul>
					{connectors.map((x) => {
						return (
							<li
								key={x.name}
								data-testid={LicenseCardConstants.ConnectorItem}
								className="text-color_dark"
							>
								{x.name} - {x.value}
							</li>
						);
					})}
				</ul>
			</div>
		</InformationBlock>
	);
};
