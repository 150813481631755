import { ThunkType } from "../index";
import { documentsActions } from "./index";
import { investigatesActions } from "../investigate";
import { DocumentsService } from "../../api/documents";

export function fetchDocuments(): ThunkType {
	return async (dispatch) => {
		dispatch(documentsActions.fetchDocumentStart());

		const response = await DocumentsService.getDocuments();

		response
			.map((data) => {
				dispatch(documentsActions.fetchDocumentSuccess(data.documents));
				dispatch(investigatesActions.getOptionsDocsSelect(data.documents));
				return data;
			})
			.getOrThrow("error", (errorMessage) => {
				dispatch(documentsActions.fetchDocumentError(errorMessage));
			});
	};
}
