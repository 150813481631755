export const environments = {
	version: process.env.REACT_APP_VERSION ?? "2021.3",
	versionDate: process.env.REACT_APP_VERSION_DATE,
};

export class EnvManager {
	static get isDev(): boolean {
		return process.env.NODE_ENV === "development";
	}
}
