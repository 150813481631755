import React from "react";
import "./RadioButton.scss";

export interface IRadioButtonData {
	name: string;
	checked: boolean;
}

export type IOnChangeRadioButton = ({ name, checked }: IRadioButtonData) => void;

export interface RadioButtonProps {
	label: string;
	checked: boolean;
	onChange: IOnChangeRadioButton;
	id: string;
	name: string;
}

export const RadioButton: React.FC<RadioButtonProps> = ({ label, checked, onChange, id, name }) => {
	const checkboxId = `radio-${id}`;

	const onClickHandle = (): void => {
		const newValue = !checked;
		onChange({ name, checked: newValue });
	};

	return (
		<>
			<div className="radiobutton-wrapper make-hover" onClick={onClickHandle}>
				<input
					type="radio"
					id={checkboxId}
					checked={checked}
					onChange={(event) => {
						event.stopPropagation();
						onClickHandle();
					}}
				/>
				<label className="text-color_dark" htmlFor={checkboxId} onClick={(e) => e.preventDefault()}>
					{label}
				</label>
			</div>
		</>
	);
};
