import { ThunkType } from "../index";
import { storyActions } from "./index";
import { InvestigateService } from "../../api/investigate";
import { RequestWithFilter, RequestWithPagination } from "../../models/common";
import { StoryInvestigatesInvestigates } from "../../models/story/StoryInvestigates";

export function fetchInvestigates(
	props: RequestWithPagination & RequestWithFilter<StoryInvestigatesInvestigates>
): ThunkType {
	return async (dispatch) => {
		dispatch(storyActions.fetchInvestigatesStart());
		const response = await InvestigateService.getInvestigateAsync(props);
		response.map(({ data, metadata }) => {
			dispatch(
				storyActions.fetchInvestigatesSuccess({
					metadata,
					investigates: data?.investigates,
				})
			);

			return data;
		});
	};
}

export function fetchSelectedInvestigate(investigateId: string): ThunkType {
	return async (dispatch) => {
		dispatch(storyActions.fetchSelectedInvestigateStart());
		const response = await InvestigateService.getInvestigateById(investigateId);

		response
			.map((data) => {
				dispatch(storyActions.fetchSelectedInvestigateSuccess(data));

				return data;
			})
			.getOrThrow("error", (errorMessage) => {
				dispatch(storyActions.fetchSelectedInvestigateError(errorMessage));
			});
	};
}
