import { API_PATH } from "./configs";
import { OldEitherResponse } from "../models/common";
import { Api } from "./api";
import { IContacts } from "../models/about/contacts";

export class AboutService {
	public static async getContacts(): Promise<OldEitherResponse<IContacts>> {
		return await new Api().oldGet<IContacts>(`/${API_PATH.contacts.getContacts}`, {
			baseURL: "/",
		});
	}

	public static async getReleaseNotes(): Promise<OldEitherResponse<string>> {
		return await new Api().oldGet<string>(`/${API_PATH.contacts.getReleaseNotes}`, {
			baseURL: "/",
		});
	}
}
