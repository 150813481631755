export enum LicenseConstants {
	File = "license-file-input",
}

export const licenseResponseStatus = {
	wrongFile: 406,
	wrongValidationLicense: 409,
	success: 200,
};

export const licenseMessageTemplate = {
	wrongFile: "Отрицательная валидация файла",
	wrongValidationLicense: "Отрицательная валидация лицензии",
	success: "Лицензия загружена",
};

export const licenseResponseMessage = ({ status, message = "" }: { status: number; message?: string }): string => {
	const messages = {
		[licenseResponseStatus.wrongFile]: licenseMessageTemplate.wrongFile,
		[licenseResponseStatus.wrongValidationLicense]: licenseMessageTemplate.wrongValidationLicense,
		[licenseResponseStatus.success]: licenseMessageTemplate.success,
	};

	return messages[status] ?? message;
};
