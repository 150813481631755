import React from "react";
import { Pagination, NoopHandle, RequestWithFilter, RequestWithPagination } from "../models/common";
import { useForm, UseFormReturnType } from "./useForm";
import { CustomTableOnChangePage } from "../components/Table/Table";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type RequestProps<Req, Resp> = RequestWithPagination & RequestWithFilter<Req>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type GetTablePageDataHandle<Req, Resp, SubResponseType extends object = {}> = ({}: RequestProps<Req, Resp> &
	SubResponseType) => Promise<void>;

interface Props<Req, Resp> {
	onGetPage?: GetTablePageDataHandle<Req, Resp> | NoopHandle;
	pagination?: Pagination;
	tableItemCountRef?: React.MutableRefObject<number | null>;
}

interface ReturnHookType<Req, Resp> {
	onGetPageData: GetTablePageDataHandle<Req, Resp>;
	onChangePage: CustomTableOnChangePage;
	pagination?: Pagination;
	formProps?: UseFormReturnType<Req>;
	requestProps: RequestProps<Req, Resp>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function useTableManager<Request extends object = {}, Response extends object = {}>({
	onGetPage,
	pagination,
	tableItemCountRef,
}: Props<Request, Response>): ReturnHookType<Request, Response> {
	const [filterForm, setFilterForm] = React.useState<Partial<Request>>({});
	const [requestProps, setRequestProps] = React.useState({} as RequestProps<Request, Response>);

	React.useEffect(() => {
		const data: RequestProps<Request, Response> = {
			paginationTarget: {
				pageSize: tableItemCountRef?.current ?? 0,
				page: pagination?.page ?? 0,
			},
			filterTarget: filterForm,
		};

		setRequestProps(data);
	}, [tableItemCountRef?.current]);

	const getPageData: GetTablePageDataHandle<Request, Response> = async ({
		paginationTarget,
		filterTarget,
		...props
	}) => {
		const subProps = props ?? {};

		const data = {
			paginationTarget: {
				pageSize: tableItemCountRef?.current ?? 0,
				page: paginationTarget?.page ?? pagination?.page ?? 0,
			},
			filterTarget: filterTarget ?? filterForm,
		};

		const newProps = { ...data, ...subProps };
		setRequestProps(newProps);
		onGetPage?.(newProps);
	};

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const formProps = useForm<Request>(
		{},
		{
			onFormChange: (formData) => {
				setFilterForm(formData);
				getPageData({
					filterTarget: formData,
				});
			},
		}
	);

	const onSetPage: CustomTableOnChangePage = async ({ pageNumber, itemsCount = 0 }) => {
		if (!tableItemCountRef!.current) {
			tableItemCountRef!.current = itemsCount;
		}

		getPageData({
			paginationTarget: {
				page: pageNumber,
				pageSize: itemsCount,
			},
		});
	};

	return {
		pagination,
		formProps,
		onChangePage: onSetPage,
		onGetPageData: getPageData,
		requestProps,
	};
}
