import React, { useState } from "react";
import { AboutService } from "../../api/about";
import { InformationBlock } from "../InformationBlocks/InformationBlock/InformationBlock";
import { IContacts } from "../../models/about/contacts";
import "./Contacts.scss";
import { ContactsConstants } from "./constants";

export const Contacts: React.FC = () => {
	const [contacts, setContacts] = useState<null | IContacts>(null);
	React.useEffect(() => {
		AboutService.getContacts().then((response) => {
			response.map((data) => {
				setContacts(data);
				return data;
			});
		});
	}, []);
	return (
		<>
			<InformationBlock>
				<div className="contacts-container">
					<div className="contacts-column contacts-column_left">
						<p
							style={{ display: "inline-flex", flexDirection: "column" }}
							className="text-color_dark-light"
						>
							Контакты технической поддержки:
							{contacts &&
								Object.keys(contacts).map((x) => {
									return (
										<p key={x} style={{ margin: 0 }} data-testid={ContactsConstants.Block}>
											{x}:&nbsp;<span className="text-color_dark">{contacts[x]}</span>
										</p>
									);
								})}
						</p>
					</div>
					{/*TODO: Gorshteyn закоментирован до добавления файла инсрукции*/}
					{/*<div className="contacts-column contacts-column_right">*/}
					{/*	<a href="#" download>*/}
					{/*		Инструкция по оформлению запроса в поддержку*/}
					{/*	</a>*/}
					{/*</div>*/}
				</div>
			</InformationBlock>
		</>
	);
};
