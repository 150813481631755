import React from "react";

interface Props {
	label: string;
	dataTestId: string;
	spanClassName?: string;
}

export const InvestigationResult: React.FC<Props> = ({ label, dataTestId, spanClassName = "" }: Props) => {
	return (
		<div className="invest-res" data-testid={dataTestId}>
			<span className={spanClassName}>{label}</span>
		</div>
	);
};
