export enum InvestigationConstants {
	Container = "investigation-container",
	Loader = "investigation-loader",
	ErrorResult = "investigation-error",
	EmptyResult = "investigation-empty-result",
	InvestigationSubmitButton = "investigation-submit-button",
	StartInvestigationResult = "start-investigation-result",
	DropZone = "investigation-dropzone",
	InvestigationFileNameResult = "investigation-filename-result",
	Download = "investigation-download",
	ImageUrl = "investigation-image-url",
}
