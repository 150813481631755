import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { externalSystemsInitialState, IExternalSystemsInitialState } from "./initialState";
import { ExternalSystemChart, IExternalSystemList } from "../../models/externalSystem/response";

const commonSlice = createSlice({
	name: "externalSystems",
	initialState: externalSystemsInitialState,
	reducers: {
		getExternalSystemList(state: IExternalSystemsInitialState, action: PayloadAction<IExternalSystemList>) {
			state.externalSystemsList = action.payload;
		},
		getDocumentsUploadedVolume(state: IExternalSystemsInitialState, action: PayloadAction<ExternalSystemChart>) {
			state.documentsUploadedVolume = action.payload;
		},
	},
});

export const externalSystemsActions = commonSlice.actions;

export default commonSlice.reducer;
