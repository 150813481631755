import { API_PATH } from "./configs";
import { StoryInvestigatesInvestigates } from "../models/story/StoryInvestigates";
import { InvestigatesType } from "../models/investigates/Investigates";
import { EitherResponse, OldEitherResponse, RequestWithFilter, RequestWithPagination } from "../models/common";
import { Api } from "./api";
import { makeTableParamsRequest } from "../helpers/api/tableHelpers";

export class InvestigateService {
	public static async getInvestigateAsync(
		requestProps: RequestWithPagination & RequestWithFilter<StoryInvestigatesInvestigates>
	): Promise<EitherResponse<{ investigates: StoryInvestigatesInvestigates[] }>> {
		// await startMocks(InvestigatesHistoryMocks);

		return await new Api().get<{ investigates: StoryInvestigatesInvestigates[] }>(
			`/${API_PATH.investigates.getInvestigates}`,
			{
				params: {
					...makeTableParamsRequest(requestProps),
				},
			}
		);
	}

	public static async getInvestigateById(investigateId: string): Promise<OldEitherResponse<InvestigatesType>> {
		return await new Api().oldGet<InvestigatesType>(`/${API_PATH.investigates.getInvestigateById(investigateId)}`);
	}

	public static async sendInvestigateData(body: FormData): Promise<
		OldEitherResponse<{
			investigateId?: string;
		}>
	> {
		return await new Api().oldPost<
			FormData,
			{
				investigateId?: string;
			}
		>(`/${API_PATH.investigates.sendInvestigateData}`, body, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
	}
}
