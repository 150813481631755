import React from "react";
import { NoopHandle } from "../models/common";

type FormValueType = string | number | boolean;

type ChangeFormHandle = ({ name, value }: { name: string; value: FormValueType }) => void;
type ChangeFormFromInputTextHandle = (event: React.ChangeEvent<HTMLInputElement>) => void;
type GetValueFromFormHandle = (name: string) => string;
type CheckFormNameHandle<T> = (name: keyof T) => string;

export interface UseFormReturnType<T> {
	form: Partial<T>;
	getValueFromFormHandle: GetValueFromFormHandle;
	changeFormHandle: ChangeFormHandle;
	changeFormFromInputTextHandle: ChangeFormFromInputTextHandle;
	checkFormNameHandle: CheckFormNameHandle<T>;
	setDefaultValueHandle: NoopHandle;
}

interface Props<T> {
	onFormChange?: (formData: Partial<T>) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useForm<T extends { [key: string]: string }, K = Partial<Record<keyof T, FormValueType>>>(
	defaultValue: Partial<T> = {},
	{ onFormChange }: Props<T> = {}
): UseFormReturnType<T> {
	const [form, setForm] = React.useState<Partial<T>>(defaultValue);

	const changeForm: ChangeFormHandle = ({ name, value }) => {
		setForm((x) => {
			const newForm = {
				...x,
				[name]: value,
			};

			onFormChange?.(newForm);

			return newForm;
		});
	};

	const changeFormFromInputText: ChangeFormFromInputTextHandle = (event) => {
		changeForm(event.target);
	};

	const getValueFromForm: GetValueFromFormHandle = (name) => {
		return form[name] ?? "";
	};

	const checkFormName: CheckFormNameHandle<T> = (name): string => {
		return name as string;
	};

	const setDefaultValue: NoopHandle = () => {
		setForm(defaultValue);
	};

	return {
		form,
		getValueFromFormHandle: getValueFromForm,
		changeFormHandle: changeForm,
		checkFormNameHandle: checkFormName,
		setDefaultValueHandle: setDefaultValue,
		changeFormFromInputTextHandle: changeFormFromInputText,
	};
}
