import React from "react";
import "./Drawer.scss";
import classNames from "classnames";
import logo from "../../../assets/logo/logo.svg";
import logoShort from "../../../assets/logo/logoShort.svg";
import { NavLink } from "react-router-dom";
import { DrawerConstants } from "./constants";
import { environments } from "../../../helpers/environments";
import { MenuToggle } from "../MenuToggle/MenuToggle";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { CURRENT_PAGE } from "../../../constants/routes";

export interface LinkType {
	to: string;
	label: string;
	exact: boolean;
	src?: string;
	icon?: React.ReactNode;
}

interface Props {
	isOpen: boolean;
	linkData: LinkType[];
	onToggleMenu: () => void;
}

export const Drawer: React.FC<Props> = ({ linkData = [], isOpen, onToggleMenu }) => {
	const { setValue: setCurrentRoutePath } = useLocalStorage<string | undefined>(CURRENT_PAGE, undefined);
	const renderLinks = (links: LinkType[]): React.ReactNodeArray => {
		return links.map((link, index) => {
			return (
				<li key={link.src ?? index} className="nav-header-li-item">
					<NavLink
						to={link.to}
						exact={link.exact}
						activeClassName="active"
						data-testid={DrawerConstants.Link}
						onClick={() => {
							setCurrentRoutePath(link.to);
						}}
					>
						{link.icon && (
							<div className="svg-container" title={!isOpen ? link.label : ""}>
								{link.icon}
							</div>
						)}
						{link.src && <img src={link.src} title={!isOpen ? link.label : ""} width="24" height="24" />}
						<span>{link.label}</span>
					</NavLink>
				</li>
			);
		});
	};

	return (
		<nav className={classNames("drawer", { close: !isOpen })} data-testid={DrawerConstants.Container}>
			<div
				className={classNames("nav-header", {
					narrow: !isOpen,
				})}
				data-testid={DrawerConstants.Header}
			>
				<img src={isOpen ? logo : logoShort} alt="Logo" />
				<MenuToggle onToggle={onToggleMenu} isOpen={isOpen} />
			</div>
			<ul>{renderLinks(linkData)}</ul>
			<NavLink to="/release" className="drawer_version-wrapper">
				{isOpen && <span>TraceDoc v.{environments.version}</span>}
				{!isOpen && <span>v.{environments.version}</span>}
			</NavLink>
		</nav>
	);
};
