import React, { useState } from "react";
import { InputForm } from "../InputForm/InputForm";
import { DropdownForm } from "../DropdownForm/DropdownForm";
import { InputDate } from "../../../UI/inputdate/InputDate";
import { InvestigationConstants } from "../../../../containers/Investigation/constants";
import {
	currentElementDropdown,
	optionsProcess,
	optionsStatus,
} from "../../../../containers/Journal/optionsDropdown/optionsDropdown";
import { IDataParameters } from "../../../../containers/Journal/Journal";
import { isPressEnterGuard } from "../../../../helpers/guards/events/events";
import { fromJSDateReturnDateWithEndDay } from "../../../../helpers/dates/dates";
import { Button } from "../../../UI/Button/Button";
import { NameValueType } from "../../../../models/common";
import { CSSObject } from "@emotion/serialize";

function validateDateFilter(dateFrom: string, dateTo: string): string {
	if ((dateTo && !dateFrom) || (dateFrom && !dateTo)) {
		return "заполните обе даты для фильтра";
	}
	return "";
}

interface Props {
	data: IDataParameters;
}

const controlStyles: CSSObject = {
	padding: "7px 12px 6px 8px",
};

export const FilterForm: React.FC<Props> = ({ data }) => {
	const { changeParametersDataHandle, parametersDate, parametersData, changeParametersDateHandle, sendResult } = data;

	const [error, setError] = useState("");

	const submitForm = (e: React.FormEvent) => {
		e.preventDefault();

		const error = validateDateFilter(
			new Date(parametersDate.dateStart!).toISOString() ?? "",
			new Date(parametersDate.dateEnd!).toISOString() ?? ""
		);
		if (error) {
			setError(error);
			setTimeout(() => {
				setError("");
			}, 3000);
		} else {
			sendResult();
		}
	};

	const sendParametersDataHandle = ({ name, value }: { name: keyof typeof parametersData; value: string }): void => {
		changeParametersDataHandle({
			label: name,
			value: value,
		});
	};

	const sendParametersFromInput = (e: React.FocusEvent<HTMLInputElement>): void => {
		const { name, value } = e.target;
		sendParametersDataHandle({ name: name as keyof typeof parametersData, value });
	};

	const onFormInputEnter = (e: React.KeyboardEvent<HTMLElement>): void => {
		const event = e as any;
		if (isPressEnterGuard(e)) {
			sendParametersFromInput(event);
		}
	};

	const onFormDropdownChange = ({ name, value }: NameValueType): void => {
		sendParametersDataHandle({
			name: name as keyof typeof parametersData,
			value: value,
		});
	};

	const checkDateRangeValue = ({ date, name }: { date: Date; name: string }): Date => {
		const typedName = name as keyof typeof parametersDate;

		if (typedName === validateFormName("dateEnd")) {
			return fromJSDateReturnDateWithEndDay(date);
		}
		return date;
	};

	const onFormDateChange = (
		{ date, name }: { date: Date; name: string },
		event?: React.SyntheticEvent<HTMLElement>
	): void => {
		let mustSendResult = false;

		const convertDate = checkDateRangeValue({ name, date });
		const obj = {
			...parametersDate,
			[name]: convertDate,
		};

		const e = event as React.KeyboardEvent<HTMLElement>;

		if (isPressEnterGuard(e)) {
			mustSendResult = !Object.values(obj).some((x) => !x);
		}

		changeParametersDateHandle(
			{
				label: name as keyof typeof parametersDate,
				value: convertDate,
			},
			mustSendResult
		);
	};

	const validateFormName = (value: keyof typeof parametersData | keyof typeof parametersDate): string => {
		return value;
	};

	return (
		<div
			className="filter-form"
			onClick={(event) => {
				event.stopPropagation();
			}}
		>
			<form onSubmit={submitForm}>
				<div className="filter-form_element">
					<div className="label-wrapper">
						<label>Дата:</label>
					</div>
					<div style={{ display: "flex" }}>
						<div className="filter-form_composite-data">
							<InputDate
								mark="От"
								selected={parametersDate.dateStart}
								name={validateFormName("dateStart")}
								changeDate={onFormDateChange}
								maxDate={parametersDate.dateEnd}
							/>
						</div>
						<span className="filter-form_line" />
						<div className="filter-form_composite-data">
							<InputDate
								mark="До"
								selected={parametersDate.dateEnd}
								name={validateFormName("dateEnd")}
								changeDate={onFormDateChange}
								minDate={parametersDate.dateStart}
							/>
						</div>
					</div>
				</div>
				<div className="filter-form_element">
					<InputForm
						id="file_name"
						label="Наименование документа"
						defaultValue={parametersData.fileName}
						name={validateFormName("fileName")}
						onChange={sendParametersFromInput}
						onKeyPress={onFormInputEnter}
					/>
				</div>

				<div className="filter-form_element">
					<DropdownForm
						id="status"
						label="Статус"
						options={optionsStatus}
						name={validateFormName("status")}
						handleChange={onFormDropdownChange}
						defaultValue={currentElementDropdown(parametersData.status, optionsStatus)}
						controlStyle={controlStyles}
					/>
				</div>

				<div className="filter-form_element">
					<DropdownForm
						id="event"
						label="Событие"
						options={optionsProcess}
						name={validateFormName("eventType")}
						handleChange={onFormDropdownChange}
						defaultValue={currentElementDropdown(parametersData.eventType, optionsProcess)}
						controlStyle={controlStyles}
					/>
				</div>

				<div className="filter-form_element">
					<InputForm
						id="external_system"
						label="Внешняя система"
						defaultValue={parametersData.externalSystemId}
						name={validateFormName("externalSystemId")}
						onChange={sendParametersFromInput}
						onKeyPress={onFormInputEnter}
					/>
				</div>

				<div className="filter-form_element">
					<InputForm
						id="userId"
						label="Получатель копии"
						defaultValue={parametersData.userId ?? ""}
						name={validateFormName("userId")}
						onChange={sendParametersFromInput}
						onKeyPress={onFormInputEnter}
					/>
				</div>

				{error && (
					<div className="filter-form_element">
						<span className="filter-form_error">{error}</span>
					</div>
				)}

				<div className="filter-submit-wrap filter-form_element">
					<Button
						type="submit"
						disabled={!!error}
						data-testid={InvestigationConstants.InvestigationSubmitButton}
						className="primary"
					>
						Поиск
					</Button>
				</div>
			</form>
		</div>
	);
};
