import React, { PropsWithChildren } from "react";
import { CloseIcon } from "../Icons/CloseIcon";

export interface ChipProps {
	click: () => void;
}

export const Chip: React.FC<PropsWithChildren<ChipProps>> = ({ children, click }) => {
	return (
		<div className="chip-item">
			<span>{children}</span>
			<span className="chip-item_icon-wrapper" onClick={click} data-testid="chip-image" title="Закрыть">
				<CloseIcon />
			</span>
		</div>
	);
};
