import React from "react";
import { LoaderConstants } from "./constants";

export const Loader: React.FC = () => (
	<div className="center" data-testid={LoaderConstants.Container}>
		<div className="center__loader">
			<div />
			<div />
		</div>
	</div>
);
