import React from "react";
import "./MenuToggle.scss";
import classNames from "classnames";
import iconBars from "../../../assets/iconBars/iconBars.svg";
import iconTimes from "../../../assets/iconTimes/iconTimes.svg";
import { MenuToggleContestants } from "./contestants";

interface Props {
	onToggle: () => void;
	isOpen: boolean;
}

export const MenuToggle: React.FC<Props> = ({ onToggle, isOpen }) => {
	return (
		<i
			className={classNames("fa", "menu-toggle", "make-hover", {
				open: isOpen,
				"menu-narrow": !isOpen,
			})}
			onClick={onToggle}
			data-testid={MenuToggleContestants.Container}
			title={isOpen ? "Скрыть меню" : "Раскрыть меню"}
		>
			{isOpen ? (
				<img src={iconTimes} alt={MenuToggleContestants.IsOpenIcon} />
			) : (
				<img src={iconBars} alt={MenuToggleContestants.IsCloseIcon} />
			)}
		</i>
	);
};
