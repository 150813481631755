import React from "react";
import "./ReportPlacePage.scss";
import { sortBy } from "lodash";
import { PieChart } from "../../components/Charts/PieChart/PieChart";
import { ChartTable } from "../../components/Charts/ChartTable/ChartTable";
import { InformationBlockWithTooltip } from "../../components/InformationBlocks/InformationBlockWithTooltip/InformationBlockWithTooltip";
import { Checkbox, ICheckBoxData } from "../../components/UI/Checkbox/Checkbox";
import { ColumnContainer } from "../../components/ColumnContainer/ColumnContainer";
import { ExternalSystemItem, IExternalSystemList } from "../../models/externalSystem/response";
import { generateCheckboxFromList } from "../../helpers/commonHelpers/commonHelpers";
import { selectDocumentsUploadedVolume, selectExternalSystemsList } from "../../store/externalSystems/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentsUploadedVolumeAsync } from "../../store/externalSystems/operations";
import { Dictionary } from "../../models/common";
import { convertExternalSystemsToPieChart } from "../../store/externalSystems/maps";
import { DocumentsUploadedVolumeRequest } from "../../models/externalSystem/request";
import { getDocumentsUploadedVolume, getExternalSystemList } from "../../api/externalSystems";
import { externalSystemsActions } from "../../store/externalSystems";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const SELECTED_EXTERNAL_SYSTEMS_KEY = "selected_external_systems_key";

export const ReportPlacePage: React.FC = () => {
	const dispatch = useDispatch();
	const documentsUploadedVolume = useSelector(selectDocumentsUploadedVolume);
	const externalSystemsList = useSelector(selectExternalSystemsList);
	const [externalSystems, setExternalSystems] = React.useState<Dictionary<ExternalSystemItem>>({});
	const [defaultExternalSystemsList, setDefaultExternalSystemsList] = React.useState<Dictionary<ExternalSystemItem>>(
		{}
	);
	const [checkedList, setCheckedList] = React.useState<Array<ICheckBoxData>>([]);
	const { storedValue: selectedExternalSystems, setValue: setSelectedExternalSystems } = useLocalStorage<
		IExternalSystemList | undefined
	>(SELECTED_EXTERNAL_SYSTEMS_KEY, undefined);

	const hasSelectedExternalSystems = Array.isArray(selectedExternalSystems);

	const memoChartData = React.useMemo(() => {
		return convertExternalSystemsToPieChart(externalSystems);
	}, [externalSystems]);

	const externalSystemsKeyList = React.useMemo(() => {
		const externalSystemsKeyList = Object.keys(defaultExternalSystemsList);
		const convertCheckedList = checkedList
			.filter((x) => externalSystemsKeyList.includes(x.name))
			.map((x) => ({
				...x,
				checked: hasSelectedExternalSystems ? selectedExternalSystems?.includes(x.name) : x.checked,
			}));

		return sortBy(convertCheckedList, "name");
	}, [defaultExternalSystemsList, checkedList]);

	const getDocumentsUploadedVolumeHandle = ({
		externalSystems = selectedExternalSystems,
		...other
	}: Partial<DocumentsUploadedVolumeRequest>): void => {
		dispatch(
			getDocumentsUploadedVolumeAsync({
				externalSystems,
				...other,
			})
		);
	};

	const generateDefaultCheckedList = () => {
		if (hasSelectedExternalSystems) {
			const defaultCheckboxList = generateCheckboxFromList(externalSystemsList, false).map((x) => ({
				...x,
				checked: !!selectedExternalSystems?.includes(x.name),
			}));

			setCheckedList(defaultCheckboxList);
		} else {
			const defaultCheckboxList = generateCheckboxFromList(externalSystemsList);
			setCheckedList(defaultCheckboxList);
		}
	};

	const toggleCheckboxItem = (target: ICheckBoxData[], { name, checked }: ICheckBoxData): ICheckBoxData[] => {
		const targetList = [...target];
		const index = targetList.findIndex((y) => y.name === name);

		targetList[index] = {
			...targetList[index],
			checked,
		};

		const selectedId = targetList.filter((x) => x.checked).map(({ name }) => name);

		setSelectedExternalSystems(selectedId);
		getDocumentsUploadedVolumeHandle({ externalSystems: selectedId });

		return targetList;
	};

	React.useEffect(() => {
		setExternalSystems(documentsUploadedVolume.externalSystems);
	}, [documentsUploadedVolume.externalSystems]);

	React.useEffect(() => {
		generateDefaultCheckedList();
	}, [externalSystemsList]);

	const getExternalSystems = async (): Promise<void> => {
		const response = await getExternalSystemList();
		response.map(({ data }) => {
			(async () => {
				const defaultExternalSystemsList = await getDocumentsUploadedVolume({
					externalSystems: data,
				});

				defaultExternalSystemsList.map(({ data: defaultList }) => {
					setDefaultExternalSystemsList(defaultList.externalSystems);
					return defaultList;
				});
			})();

			getDocumentsUploadedVolumeHandle({
				externalSystems: hasSelectedExternalSystems ? selectedExternalSystems : data,
			});
			dispatch(externalSystemsActions.getExternalSystemList(data));

			return data;
		});
	};

	React.useEffect(() => {
		getExternalSystems();
	}, []);

	return (
		<div className="report-container">
			{/*<InformationBlockWithTooltip tooltipContent={<></>}>1</InformationBlockWithTooltip>*/}
			<InformationBlockWithTooltip
				iconTitle="Выбрать внешние системы"
				tooltipContainerStyles={{
					padding: "11px 7px",
				}}
				label="Загрузка документов по внешним системам"
				tooltipContent={
					<ColumnContainer
						scrollStyles={{
							maxHeight: "237px",
						}}
					>
						{externalSystemsKeyList.map((x) => {
							return (
								<Checkbox
									key={x.name}
									label={x.name}
									onChange={(value) => {
										setCheckedList((x) => {
											return toggleCheckboxItem(x, value);
										});
									}}
									id={x.name}
									name={x.name}
									checked={!!x.checked}
								/>
							);
						})}
					</ColumnContainer>
				}
			>
				<div className="chart-container">
					<div className="report-chart__data">
						<div className="report-chart__column">
							<PieChart chartData={memoChartData} data={externalSystems} />
						</div>
						<div className="report-chart__column">
							<ChartTable data={externalSystems} chartColorList={memoChartData.colorList} />
						</div>
					</div>
				</div>
			</InformationBlockWithTooltip>
		</div>
	);
};
