import React from "react";

export const ChartIcon: React.FC = () => {
	return (
		<>
			<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M17.0351 4.76814C17.0343 4.76018 17.0329 4.75243 17.0316 4.74468C17.0311 4.7421 17.0309 4.73943 17.0304 4.73685C17.0286 4.72759 17.0262 4.71855 17.0236 4.70964L17.0228 4.70632C17.02 4.69715 17.0168 4.6882 17.0132 4.67941L17.0122 4.67666C17.0088 4.66826 17.0048 4.66013 17.0007 4.65212L16.9988 4.64807C16.9948 4.64062 16.9905 4.63348 16.986 4.62637C16.9848 4.62456 16.9839 4.62267 16.9827 4.6209C16.9783 4.61427 16.9735 4.60799 16.9686 4.6017C16.967 4.59964 16.9656 4.59748 16.9639 4.59546C16.9579 4.58801 16.9514 4.58091 16.9447 4.57402L16.9429 4.57204L12.5022 0.094544L12.4998 0.0923483C12.4931 0.0857613 12.4863 0.0793894 12.4791 0.0734051C12.4767 0.0714677 12.4742 0.0698317 12.4718 0.0679804C12.4659 0.0633738 12.4601 0.0587671 12.4539 0.054591C12.4517 0.0531272 12.4494 0.0519217 12.4472 0.050501C12.4406 0.0462387 12.4339 0.0420196 12.427 0.038274L12.4222 0.0359922C12.4145 0.0319883 12.4066 0.0281135 12.3986 0.0247123L12.3954 0.0235499C12.3868 0.0200626 12.3781 0.0167906 12.3691 0.0140352L12.3657 0.0131311C12.3569 0.0105479 12.3479 0.00818004 12.3388 0.00632877C12.3361 0.00576908 12.3334 0.00555382 12.3306 0.00508023C12.3231 0.0037456 12.3155 0.00241096 12.3077 0.00163601C12.2972 0.000559687 12.2865 0 12.2758 0H1.68659C0.756617 0 0 0.762896 0 1.70059V20.2994C0 21.2371 0.756617 22 1.68659 22H15.3501C16.2801 22 17.0367 21.2371 17.0367 20.2994V4.80039C17.0367 4.78959 17.0361 4.77882 17.0351 4.76814ZM12 1L15.5 4.5H13C12 4.5 12 4 12 3.5V1ZM15.3501 21H1.68659C1.5 21 1 21 1 20.2994V11V1.70059C1 1 1.5 1 1.68659 1H11V3.5C11 5 12 5.5 13 5.5H16V20.2994C16 20.8073 15.5 21 15.3501 21Z"
					fill="#5176A1"
				/>
				<circle cx="4" cy="15" r="1" fill="#5176A1" />
				<circle cx="7" cy="11" r="1" fill="#5176A1" />
				<circle cx="10" cy="15" r="1" fill="#5176A1" />
				<circle cx="14" cy="8" r="1" fill="#5176A1" />
				<path d="M4 15L7 11L10 15L14 8" stroke="#5176A1" />
			</svg>
		</>
	);
};
