import React from "react";
import "./Settings.scss";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { InformationBlock } from "../../components/InformationBlock/InformationBlock";
import { selectNotificationTimeList } from "../../store/settings/selectors";
import { getNotificationsSettingsAsync, setNotificationsSettingsAsync } from "../../store/settings/operations";
import { NotificationsSettings } from "../../models/notificationsSettings/response";
import { RadioButtonList, RadioList } from "../../components/UI/RadioList/RadioList";

export const Settings: React.FC = () => {
	const dispatch = useDispatch();
	const notificationTimeList = useSelector(selectNotificationTimeList);

	React.useEffect(() => {
		dispatch(getNotificationsSettingsAsync());
	}, []);

	const selectNotificationTimeItem = ({
		target,
		parentIndex,
	}: {
		parentIndex: number;
		target: RadioButtonList;
	}): void => {
		const newList = cloneDeep(notificationTimeList as Array<NotificationsSettings>);

		newList[parentIndex] = {
			...newList[parentIndex],
			options: newList[parentIndex].options.map((y, index) => {
				return {
					...y,
					isSelected: target[index].checked,
				};
			}),
		};

		dispatch(setNotificationsSettingsAsync(newList));
	};

	return (
		<div className="settings-wrapper">
			{notificationTimeList.map((x, parentIndex) => {
				return (
					<div className="notifications-setting__container" key={x.type}>
						<InformationBlock
							style={{
								display: "flex",
								flexDirection: "column",
								width: "400px",
							}}
						>
							<p className="notifications-setting__list-label" role="label">
								{x.name}
							</p>
							<div className="notifications-setting__list-item">
								<RadioList
									onSelect={(targetList) => {
										selectNotificationTimeItem({
											target: targetList,
											parentIndex,
										});
									}}
									data={x.options.map((y) => {
										return {
											...y,
											checked: y.isSelected,
										};
									})}
								/>
							</div>
						</InformationBlock>
					</div>
				);
			})}
		</div>
	);
};
