import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContent } from "react-toastify/dist/types";

interface Props {
	status: boolean;
	title: ToastContent;
	setStatus: () => void;
	setFlag: (flag: boolean) => void;
	flag: boolean;
	autoCloseTime?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function notify<NotifyProps>({
	title,
	setStatus,
	setFlag,
	autoCloseTime,
}: {
	title: Props["title"];
	setStatus: Props["setStatus"];
	setFlag: Props["setFlag"];
	autoCloseTime?: number;
}): void {
	toast(title, {
		autoClose: autoCloseTime,
		hideProgressBar: true,
		onClose: () => {
			setStatus();
			setFlag(true);
		},
	});
}

export const ToastSuccess: React.FC<Props> = ({ status, title, setStatus, setFlag, flag, autoCloseTime = 5000 }) => {
	useEffect(() => {
		if (status && flag) {
			setFlag(false);
			notify({ title, setStatus, setFlag, autoCloseTime });
		}
	});

	return (
		<ToastContainer
			limit={10}
			position="bottom-right"
			hideProgressBar={false}
			autoClose={false}
			newestOnTop={true}
			closeOnClick={false}
			draggable={false}
			rtl={false}
		/>
	);
};
