import React from "react";
import "./ColumnContainer.scss";
import { ColumnContainerConstants } from "./ColumnContainer.constants";
import { ScrollBox } from "../ScrollBox/ScrollBox";
import { htmlDomNodeSetCssVariable } from "../../helpers/commonHelpers/commonHelpers";

interface Props {
	count?: number;
	scrollStyles?: React.CSSProperties;
}

export const ColumnContainer: React.FC<Props> = ({ children, count = 2, scrollStyles = {} }) => {
	const containerRef = React.useRef<HTMLDivElement | null>(null);
	const [columnCount] = React.useState(count);

	React.useEffect(() => {
		htmlDomNodeSetCssVariable(containerRef, {
			"column-container--count": columnCount,
		});
	}, []);

	return (
		<div
			className="column-container"
			ref={containerRef}
			data-count={columnCount}
			data-testid={ColumnContainerConstants.Container}
		>
			<ScrollBox className="column-container--row" style={scrollStyles}>
				{React.Children.map(children, (x) => {
					return <div className="column-container--col">{x}</div>;
				})}
			</ScrollBox>
		</div>
	);
};
