import React, { MouseEventHandler, useEffect, useRef } from "react";
import "./Investigation.scss";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../components/UI/Loader/Loader";
import Dropzone from "react-dropzone";
import uploadFile from "../../assets/uploadFile/uploadFile.svg";
import { Button } from "../../components/UI/Button/Button";
import { Modal } from "../../components/Modals/Modal/Modal";
import { Dropdown } from "../../components/UI/Dropdown/Dropdown";
import { fetchDocuments } from "../../store/documents/operations";
import { TableModal } from "../../components/Modals/TableModal/TableModal/TableModal";
import { fetchInvestigate } from "../../store/investigate/operations";
import { ceilNumberWithPercent } from "../../helpers/commonHelpers/commonHelpers";
import { selectAllDocuments } from "../../store/documents/documentsSelectors";
import {
	selectCurrentValueNameDoc,
	selectCurrentValuePageNumber,
	selectDocumentId,
	selectDocumentsOptions,
	selectFiles,
	selectImageUrl,
	selectInvestigatesError,
	selectInvestigatesLoading,
	selectPageNumber,
	selectPagesOptions,
	selectResultInvestigate,
} from "../../store/investigate/investigatesSelectors";
import { investigatesActions } from "../../store/investigate";
import { InvestigationConstants } from "./constants";
import { NamedProps } from "react-select/src/Select";
import { CustomFileListType } from "../../store/investigate/initialState";
import { InvestigationResult } from "./InvestigationResult/InvestigationResult";

const style: React.CSSProperties = {
	borderRadius: "26px",
	padding: "18px 20px",
	alignItems: "center",
	justifyContent: "center",
	cursor: "pointer",
	display: "flex",
	flexDirection: "column",
};

const fitStyle: React.CSSProperties = {
	...style,
	width: "fit-content",
	marginLeft: "15px",
};

export const Investigation: React.FC = () => {
	const dispatch = useDispatch();
	const sectionRef = useRef<HTMLDivElement | null>(null);
	const sectionHeight = useRef<number | null>(null);

	const [modalActive, setModalActive] = React.useState(false);
	const documents = useSelector(selectAllDocuments);
	const documentId = useSelector(selectDocumentId);

	const documentsOptions = useSelector(selectDocumentsOptions);
	const pagesOptions = useSelector(selectPagesOptions);
	const pageNumber = useSelector(selectPageNumber);
	const imageUrl = useSelector(selectImageUrl);
	const loading = useSelector(selectInvestigatesLoading);
	const files = useSelector(selectFiles);
	const resultInvestigate = useSelector(selectResultInvestigate);
	const currentValueNameDoc = useSelector(selectCurrentValueNameDoc);
	const currentValuePageNumber = useSelector(selectCurrentValuePageNumber);
	const error = useSelector(selectInvestigatesError);

	useEffect(() => {
		if (sectionRef.current) {
			const size = sectionRef.current?.getBoundingClientRect()?.height;

			const paddingTop = window.getComputedStyle(sectionRef.current)?.paddingTop;
			const paddingBottom = window.getComputedStyle(sectionRef.current)?.paddingBottom;
			sectionHeight.current = size - parseFloat(paddingTop) - parseFloat(paddingBottom);
		}
	});

	useEffect(() => {
		dispatch(fetchDocuments());
	}, []);

	const onDrop = (eventFiles: CustomFileListType[]): void => {
		const file = eventFiles[0];
		const reader = new FileReader();

		reader.onloadend = () => {
			dispatch(
				investigatesActions.setDropFile({
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					imageUrl: reader.result,
					files: eventFiles,
				})
			);
		};
		if (file) {
			reader.readAsDataURL(file);
			investigatesActions.setDropFile({
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				imageUrl: reader.result,
				files: eventFiles,
			});
		}
	};

	const onChangeNameDoc: NamedProps["onChange"] = (e) => {
		const document = documents.find((val) => val.documentId === e!.value);
		dispatch(investigatesActions.setIdDoc(document!.id));

		dispatch(investigatesActions.getOptionsPageSelect(document?.pageCount ?? 0));
		dispatch(investigatesActions.setValueNameDoc(document!));
	};

	const onChangeNumber: NamedProps["onChange"] = (e) => {
		dispatch(investigatesActions.setPageNumber(e!.value));
		dispatch(investigatesActions.setValuePageNumber(+e!.value));
	};

	const isValidation = () => {
		return !(documentId && imageUrl && pageNumber);
	};

	const startInvestigation: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		const body = new FormData();

		body.append("file", files[0]);
		body.append("fileName", files[0].name);
		body.append("documentId", documentId);
		body.append("pageNumber", pageNumber!);

		dispatch(fetchInvestigate(body));
	};

	const formattedPage = (): NamedProps["defaultValue"] => {
		if (!!currentValuePageNumber?.value && !!currentValuePageNumber?.label) {
			const { value, label } = currentValuePageNumber;

			return {
				label: label.toString(),
				value: value.toString(),
			};
		}
	};

	const getResultInvestigate = () => {
		if (loading) {
			return (
				<div style={{ marginTop: "40px" }} data-testid={InvestigationConstants.Loader}>
					<Loader />
				</div>
			);
		}

		if (error) {
			return <InvestigationResult label={error} dataTestId={InvestigationConstants.ErrorResult} />;
		}

		if (resultInvestigate) {
			if (resultInvestigate.culprit) {
				return (
					<div className="text-center w-100" data-testid={InvestigationConstants.Container}>
						<table>
							<tbody>
								<tr>
									<th>Идентификатор пользователя</th>
									<th>{resultInvestigate.culprit?.userId}</th>
								</tr>
								<tr>
									<th>Вероятность</th>
									<th>{ceilNumberWithPercent(resultInvestigate?.culprit?.confidence)}</th>
								</tr>
								{resultInvestigate?.culprit?.parameters?.map((x, index) => {
									return (
										<tr key={`${x.value}-${index}`}>
											<th>{x.name}</th>
											<th>{x.value}</th>
										</tr>
									);
								})}
							</tbody>
						</table>
						<div style={{ marginTop: "20px" }}>
							<Button onClick={() => setModalActive(true)} type="button" className="primary">
								Все данные
							</Button>
						</div>
					</div>
				);
			} else {
				return (
					<InvestigationResult
						dataTestId={InvestigationConstants.EmptyResult}
						label="Нет результата"
						spanClassName="empty-result"
					/>
				);
			}
		} else {
			return (
				<InvestigationResult
					dataTestId={InvestigationConstants.StartInvestigationResult}
					label="Начните расследование для получения результата"
				/>
			);
		}
	};

	return (
		<div className="container investigation">
			<div className="investigation-event">
				<form className="flex-grow-1 h-100">
					<Dropdown
						options={documentsOptions}
						handleChange={onChangeNameDoc}
						defaultValue={currentValueNameDoc}
						label="Выберите оригинальный файл"
						disabled={!!loading}
					/>
					<div style={{ display: "flex", alignItems: "baseline" }}>
						<label style={{ marginRight: "10px", marginLeft: "15px" }} className="text-color_dark-light">
							Номер страницы:
						</label>
						<Dropdown
							options={pagesOptions}
							handleChange={onChangeNumber}
							defaultValue={formattedPage()}
							label=""
							style={{ width: "70px" }}
							disabled={!!(pagesOptions.length === 0 || loading)}
						/>
					</div>

					<Dropzone onDrop={onDrop} disabled={loading}>
						{({ getRootProps, getInputProps }) => (
							<section className="flex-grow-1">
								<div
									{...getRootProps({ className: "dropzone bg-color_grey-light flex-grow-1" })}
									style={imageUrl ? fitStyle : style}
									ref={sectionRef}
								>
									<input {...getInputProps()} data-testid={InvestigationConstants.DropZone} />
									{imageUrl ? (
										<img
											className="max-w-100"
											src={imageUrl}
											data-testid={InvestigationConstants.ImageUrl}
											style={{
												maxHeight: sectionHeight.current + "px",
											}}
										/>
									) : (
										<>
											<p>
												<img src={uploadFile} alt="upload" />
											</p>
											<p
												data-testid={InvestigationConstants.Download}
												className="text-center text-color_dark-light"
											>
												Нажмите для загрузки или <br /> перетащите файл в данную область
											</p>
										</>
									)}
								</div>
								<aside>
									<p className="text-color_dark-light">
										Расследуемый файл:{" "}
										{files.length > 0 && (
											<span
												className="text-color_dark"
												data-testid={InvestigationConstants.InvestigationFileNameResult}
											>
												{files[0].path}
											</span>
										)}
									</p>
								</aside>
							</section>
						)}
					</Dropzone>

					<div className="btn-wrapper">
						<Button
							onClick={startInvestigation}
							type="submit"
							disabled={isValidation() || loading}
							dataTestId={InvestigationConstants.InvestigationSubmitButton}
							className="primary"
						>
							Расследовать
						</Button>
					</div>
				</form>
			</div>
			<div className="investigation-result">
				<div className="investigation-result_header">Результат расследования</div>
				<div className="investigation-result_body">{getResultInvestigate()}</div>
			</div>

			<Modal label="Сведения" active={modalActive} setActive={setModalActive}>
				{resultInvestigate && <TableModal data={resultInvestigate} />}
			</Modal>
		</div>
	);
};
