import React from "react";
import { Layout } from "./components/Layouts/Layout";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Story } from "./containers/Story/Story/Story";
import { Journal } from "./containers/Journal/Journal";
import { Investigation } from "./containers/Investigation/Investigation";
import Keycloak from "keycloak-js";
import { About } from "./containers/About/About";
import { ReleaseNotes } from "./containers/ReleaseNotes/ReleaseNotes";
import { localStorageKeyList } from "./constants/storage";
import { ReportPlacePage } from "./containers/ReportPlacePage/ReportPlacePage";
import { Settings } from "./containers/Settings/Settings";
import { appRoutes, CURRENT_PAGE } from "./constants/routes";
import { useLocalStorage } from "./hooks/useLocalStorage";

export const App: React.FC = () => {
	const history = useHistory();
	const [keycloak, setKeycloak] = React.useState<Keycloak.KeycloakInstance | null>(null);
	const [authenticated, setAuthenticated] = React.useState(false);
	const { storedValue: currentRoutePath } = useLocalStorage<string | undefined>(CURRENT_PAGE, undefined);

	React.useEffect(() => {
		(async () => {
			const keycloakInstance = Keycloak("/keycloak.json");
			window.keycloak = keycloakInstance;
			keycloakInstance.init({ onLoad: "login-required" }).success((authenticated) => {
				setKeycloak(keycloakInstance);
				setAuthenticated(authenticated);
				if (typeof currentRoutePath === "string") {
					history.push(currentRoutePath);
				} else {
					history.push(`/${appRoutes.investigation}`);
				}
			});
			keycloakInstance.onTokenExpired = () => {
				keycloakInstance.updateToken(5).success(() => {
					window.keycloak = keycloakInstance;
				});
			};
		})();

		window.localStorage.removeItem(localStorageKeyList.parametersData);
	}, []);

	if (keycloak && authenticated) {
		const routes = (
			<Switch>
				<Route exact path={`/${appRoutes.investigation}`} component={Investigation} />
				<Route exact path={`/${appRoutes.story}`} component={Story} />
				<Route exact path={`/${appRoutes.journal}`} component={Journal} />
				<Route exact path={`/${appRoutes.about}`} component={About} />
				<Route exact path={`/${appRoutes.release}`} component={ReleaseNotes} />
				<Route exact path={`/${appRoutes.settings}`} component={Settings} />
				<Route exact path={`/${appRoutes.report}`} component={ReportPlacePage} />
				<Route exact path="/">
					<Redirect to={`/${appRoutes.investigation}`} />
				</Route>
			</Switch>
		);

		return <Layout>{routes}</Layout>;
	}
	return <div>Initializing Keycloak...</div>;
};
