import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { settingsInitialState, SettingsInitialState } from "./initialState";
import { NotificationsSettingsResponse } from "../../models/notificationsSettings/response";

const commonSlice = createSlice({
	name: "settings",
	initialState: settingsInitialState,
	reducers: {
		getNotificationsSettings(state: SettingsInitialState, action: PayloadAction<NotificationsSettingsResponse>) {
			state.notificationTimeList = action.payload;
		},
	},
});

export const notificationSettingsActions = commonSlice.actions;

export default commonSlice.reducer;
