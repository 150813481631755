import { DateTime } from "luxon";
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from "../../constants/common";

export const CURRENT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function formatDateTime(date: string, format = DATE_WITH_TIME_FORMAT): string {
	return DateTime.fromISO(date).setZone(CURRENT_TIMEZONE).toFormat(format);
}

export function convertDateFromDateJS(date: Date, format: string = DATE_FORMAT): string {
	return DateTime.fromJSDate(date).toFormat(format);
}

export function fromJSDateToServerTime(date: Date): string {
	return DateTime.fromJSDate(date).toUTC().toISO();
}

export function fromJSDateReturnDateWithEndDay(date: Date): Date {
	const convertDate = fromJSDateToServerTime(date);

	return DateTime.fromISO(convertDate).endOf("day").toJSDate();
}
