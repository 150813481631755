import React, { MouseEventHandler } from "react";
import "./Button.scss";
import classNames from "classnames";
import { ButtonConstants } from "./constants";

export type ButtonType = "submit" | "button";
export type ButtonClass = "primary" | "success" | "transparent";

interface Props {
	type?: ButtonType;
	disabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	dataTestId?: string;
	className?: ButtonClass;
}

export const Button: React.FC<Props> = ({
	className = "",
	onClick,
	dataTestId,
	type = "button",
	disabled,
	children,
}) => {
	return (
		<button
			data-testid={dataTestId || ButtonConstants.Container}
			onClick={onClick}
			className={classNames("button", {
				[className]: className,
			})}
			disabled={disabled}
			type={type}
		>
			{children}
		</button>
	);
};
