import React from "react";
import { StoryInvestigatesInvestigates } from "../../../models/story/StoryInvestigates";
import { ceilNumberWithPercent } from "../../../helpers/commonHelpers/commonHelpers";
import { TablePartRow } from "../../../components/Modals/TableModal/TablePartRow";
import { Loader } from "../../../components/UI/Loader/Loader";
import { formatDateTime } from "../../../helpers/dates/dates";

type PropsTooltipBody = (
	userId: string,
	confidence: string | number,
	parametrs: StoryInvestigatesInvestigates["result"][number]["parameters"],
	copyTime: string
) => React.ReactNodeArray | React.ReactNode;

export const renderBodyTooltip: PropsTooltipBody = (userId, confidence, parameters = [], copyTime) => {
	if (userId && confidence) {
		return (
			<>
				<div className="table__width-grid table__width-parts">
					<div className="table__width-number__body">
						<div className="table__width-number__body-row">
							<div className="table__width-number__column">
								<TablePartRow
									rowParentClass="table__width-parts__column-row"
									leftPart="Вероятность"
									rightPart={ceilNumberWithPercent(confidence)}
								/>
								<TablePartRow
									rowParentClass="table__width-parts__column-row"
									leftPart="Время создания копии"
									rightPart={formatDateTime(copyTime)}
								/>

								<TablePartRow
									rowParentClass="table__width-parts__column-row"
									leftPart="Пользователь"
									rightPart={userId}
								/>
								{parameters?.map((item) => (
									<TablePartRow
										rowParentClass="table__width-parts__column-row"
										key={item.value}
										leftPart={item.name}
										rightPart={item.value}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		<>
			<div style={{ marginTop: "40px" }}>
				<Loader />
			</div>
			;
		</>;
	}
};


