import { RequestWithFilter, RequestWithPagination } from "../../models/common";

export function makeTableParamsRequest<T>({
	filterTarget,
	paginationTarget,
}: RequestWithPagination & RequestWithFilter<T>): RequestWithFilter<T> & {
	page: number;
	pageSize: number;
} {
	return {
		...filterTarget,
		page: Math.max(0, paginationTarget?.page ?? 0),
		pageSize: paginationTarget?.pageSize ?? 0,
	};
}
