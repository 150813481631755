export enum TableConstants {
	Container = "table-container",
	TotalPages = "pagination-total-pages",

	AllTableItems = "pagination-all-table-items",
}

export enum TablePaginationImageConstants {
	FirstImage = "pagination-img-first",
	PrevImage = "pagination-img-previous",
	NextImage = "pagination-img-next",
	LastImage = "pagination-img-last",
}
