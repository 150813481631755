import React from "react";
import "./PieChart.scss";
import { Doughnut } from "react-chartjs-2";
import * as Chart from "chart.js";
import { ExternalSystemChart } from "../../../models/externalSystem/response";
import { convertSizeToTextFormat } from "../../../helpers/commonHelpers/commonHelpers";

export interface PieChartProps {
	chartData: Chart.ChartData | ((canvas: HTMLCanvasElement) => Chart.ChartData);
	options?: Chart.ChartOptions;
	data: ExternalSystemChart["externalSystems"];
}

export const PieChart: React.FC<PieChartProps> = React.memo(({ chartData, data }) => {
	const EMPTY_VALUE = 0;
	const [size] = React.useMemo(() => {
		return Object.values(data).reduce(
			(prev, next) => {
				const [size] = prev;
				const { documentsSize } = next;

				const newSize = size + documentsSize;

				return [newSize];
			},
			[EMPTY_VALUE]
		);
	}, [data]);
	return (
		<div className="report-chart">
			<Doughnut
				data={chartData}
				options={{
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					cutout: "75%",
					spacing: 0,
					responsive: true,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					hover: { mode: null },
					plugins: {
						legend: {
							display: false,
						},
						tooltip: {
							callbacks: {
								title() {
									return "";
								},
								label(tooltipItems) {
									return tooltipItems.label;
								},
							},
							backgroundColor: "#fff",
							bodyColor: "#000",
							titleColor: "#000",
						},
					},
				}}
			/>

			<div className="information-box">
				<p className="information-box__label text-color_dark-blue">{convertSizeToTextFormat(size)}</p>
			</div>
		</div>
	);
});
