import React from "react";
import { IconContainer, IconContainerProps } from "./IconContainer";

type Props = IconContainerProps;

export const WarningIcon: React.FC<Props> = ({ children, ...props }) => {
	return (
		<IconContainer {...props}>
			<svg width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M16.7875 5L26.6469 22.0769C27.4167 23.4103 26.4544 25.0769 24.9148 25.0769H5.19608C3.65648 25.0769 2.69423 23.4103 3.46403 22.0769L13.3234 5C14.0932 3.66667 16.0177 3.66667 16.7875 5Z"
					stroke="#FE9100"
					strokeWidth="2"
				/>
				<rect x="14.0898" y="8.20935" width="1.93162" height="9.65812" rx="0.965812" fill="#FE9100" />
				<path
					d="M16.5044 20.765C16.5044 21.5651 15.8558 22.2137 15.0557 22.2137C14.2555 22.2137 13.6069 21.5651 13.6069 20.765C13.6069 19.9649 14.2555 19.3163 15.0557 19.3163C15.8558 19.3163 16.5044 19.9649 16.5044 20.765Z"
					fill="#FE9100"
				/>
			</svg>
		</IconContainer>
	);
};
