import React, { PropsWithChildren } from "react";
import "./Modal.scss";
import classNames from "classnames";
import { ModalTestId } from "./constants";
import { InfoIcon } from "../../Icons/InfoIcon";
import { CloseIcon } from "../../Icons/CloseIcon";
import { Button } from "../../UI/Button/Button";

interface Props {
	active: boolean;
	setActive: (value: boolean) => void;
	icon?: React.ReactNode | React.ReactNodeArray;
	label: React.ReactNode | React.ReactNodeArray | string;
}

export const Modal: React.FC<PropsWithChildren<Props>> = ({ active, setActive, label, icon, children }) => {
	return (
		<div
			data-testid={ModalTestId.Container}
			className={classNames("modal-container", {
				active: active,
			})}
			onClick={() => {
				setActive(false);
			}}
		>
			<div className="modal-inner" onClick={(e) => e.stopPropagation()}>
				<Button
					data-testid={ModalTestId.Image}
					onClick={() => {
						setActive(false);
					}}
					className="transparent"
				>
					<CloseIcon />
				</Button>
				<div className="modal-header">
					<div className="modal-header__icon-wrapper">{icon ?? <InfoIcon />}</div>
					{label}
				</div>

				<div className="modal-body">{children}</div>
			</div>
		</div>
	);
};
