import React from "react";

export const DropdownIcon: React.FC = () => {
	return (
		<>
			<svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M3.99986 5L-5.26167e-07 7.15413e-07L8 9.53674e-07L3.99986 5Z" fill="#5176A1" />
			</svg>
		</>
	);
};
