import React, { useState } from "react";
import "./Story.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvestigates, fetchSelectedInvestigate } from "../../../store/story/operations";
import { Modal } from "../../../components/Modals/Modal/Modal";
import { TableModal } from "../../../components/Modals/TableModal/TableModal/TableModal";
import {
	selectInvestigates,
	selectInvestigatesMetadata,
	selectSelectedInvestigate,
} from "../../../store/story/storeysSelectors";
import { StoryConstants } from "./constants";
import { storyActions } from "../../../store/story";
import { SubCustomTableProps, Table } from "../../../components/Table/Table";
import { formatDateTime } from "../../../helpers/dates/dates";
import { StoryInvestigatesInvestigates } from "../../../models/story/StoryInvestigates";
import { ceilNumberWithPercent } from "../../../helpers/commonHelpers/commonHelpers";
import { Tooltip } from "../../../components/Tooltips/Tooltip/Tooltip";
import { BodyTableConstants } from "../BodyTable/constants";
import { renderBodyTooltip } from "../BodyTable/BodyTable";
import { StatusInvest } from "../../../constants/status";
import icon from "../../../assets/allData/allData.svg";
import { HeaderGroupsType, MakeFilterColumnHandle } from "../../../components/Table/Table.types";
import { GetTablePageDataHandle, useTableManager } from "../../../hooks/useTableManager";

export interface ConvertStoryInvestigatesInvestigates extends Object, StoryInvestigatesInvestigates {
	confidence: string;
	statusInvest: boolean;
}

export const Story: React.FC = () => {
	const dispatch = useDispatch();
	const [modalActive, setModalActive] = useState(false);
	const tableItemCountRef = React.useRef<number | null>(null);

	const investigates = useSelector(selectInvestigates);
	const selectedInvestigate = useSelector(selectSelectedInvestigate);
	const investigatePagination = useSelector(selectInvestigatesMetadata);

	const onGetPageDataHandle: GetTablePageDataHandle<
		StoryInvestigatesInvestigates,
		{ investigates: StoryInvestigatesInvestigates[] }
	> = async (props) => {
		dispatch(fetchInvestigates(props));
	};

	const {
		formProps,
		onChangePage,
		pagination: investigatesPagination,
	} = useTableManager({
		pagination: investigatePagination,
		onGetPage: onGetPageDataHandle,
		tableItemCountRef,
	});

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const { form, getValueFromFormHandle, changeFormFromInputTextHandle } = formProps;

	const printerTableManager: SubCustomTableProps = {
		pagination: investigatesPagination,
		onChangePage,
	};

	const clearSelectInvestigate = (value: boolean) => {
		setModalActive(value);
		setTimeout(() => {
			dispatch(storyActions.clearSelectedInvestigate());
		}, 100);
	};

	const formatRecords = (data: StoryInvestigatesInvestigates[] = []): ConvertStoryInvestigatesInvestigates[] => {
		return data.map((x) => {
			const { confidence = "" } = x.result[0] ?? [];

			return {
				...x,
				date: formatDateTime(x.date),
				confidence: ceilNumberWithPercent(confidence),
				statusInvest: x.status === StatusInvest.Fail,
			};
		});
	};

	const makeFilterColumn = React.useCallback<MakeFilterColumnHandle<StoryInvestigatesInvestigates>>(
		(props) => {
			return {
				...props,
				onChange: changeFormFromInputTextHandle,
				name: props.name,
				filterValue: getValueFromFormHandle(props.name),
				placeholder: props.placeholder ?? "",
			};
		},
		[form]
	);

	const columns: HeaderGroupsType<ConvertStoryInvestigatesInvestigates> = [
		{
			Header: "Info",
			columns: [
				{
					Header: "Дата и время",
					accessor: "date",
					width: 250,
				},
				{
					id: "fileName",
					accessor: "fileName",
					filterConfigs: makeFilterColumn({
						placeholder: "Наименование документа",
						filterValue: "",
						name: "fileName",
					}),
					width: 250,
				},
				{
					Header: "Получатель документа",
					id: "result",
					accessor: ({ result = [] }) => {
						const { userId = "", confidence = "", parameters = [], copyTime = "" } = result[0] ?? [];

						return (
							<div>
								<Tooltip tooltipContent={renderBodyTooltip(userId, confidence, parameters, copyTime)}>
									<span className="text-color__primary" data-testid={BodyTableConstants.UserId}>
										{userId}
									</span>
								</Tooltip>
							</div>
						);
					},
				},
				{
					Header: "Вероятность",
					accessor: "confidence",
				},

				{
					Header: "",
					id: "statusInvest",
					accessor: ({ statusInvest, investigateId }) => {
						return (
							<div className="text-right" style={{ marginRight: "15px" }}>
								<img
									data-testid={BodyTableConstants.Image}
									/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
									// @ts-ignore
									disabled={statusInvest}
									style={{ cursor: "pointer" }}
									src={icon}
									title="Все данные расследования"
									alt="avatar"
									onClick={() => {
										dispatch(fetchSelectedInvestigate(investigateId));
										setModalActive(true);
									}}
								/>
							</div>
						);
					},
				},
			],
		},
	];

	return (
		<div className="container story-container" data-testid={StoryConstants.Container}>
			<Table<ConvertStoryInvestigatesInvestigates>
				columns={columns}
				data={formatRecords(investigates)}
				getNewRowProps={(row) => {
					let style = {};
					if (row.original.status === StatusInvest.Fail) {
						style = { background: "#e8b9b9" };
					}
					return row.getRowProps({
						style,
					});
				}}
				{...printerTableManager}
			/>

			{modalActive && (
				<Modal label="Все данные расследования" active={modalActive} setActive={clearSelectInvestigate}>
					<TableModal data={selectedInvestigate} />
				</Modal>
			)}
		</div>
	);
};
