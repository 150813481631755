import React from "react";
import { TablePartRow } from "../TablePartRow";
import { InvestigatesType } from "../../../../models/investigates/Investigates";
import { ceilNumberWithPercent } from "../../../../helpers/commonHelpers/commonHelpers";
import { ItemsTableModalConstants } from "../ItemsTableModal/constants";
import { formatDateTime } from "../../../../helpers/dates/dates";

interface Props {
	data: InvestigatesType | null;
}

export const TableModal: React.FC<Props> = ({ data }) => {
	return (
		<div className="table__width-number table__width-grid">
			<div className="table__width-number__header">
				<div className="table__width-number__header-row">
					<div className="table__width-number__column table__width-number__column--left">
						<div className="table__width-number__item  item-index__label">
							<span>№</span>
						</div>
					</div>
					<div className="table__width-number__column table__width-number__column--right">
						<div className="table__width-number__column-row">
							<div className="table__width-number__item item-index__label">
								<span>Результат расследования</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="table__width-number__body">
				{data?.raw?.map((x, index) => {
					return (
						<div
							className="table__width-number__body-row"
							key={`${x.userId}-${index}`}
							data-testid={ItemsTableModalConstants.TableItem}
						>
							<div className="table__width-number__column table__width-number__column--left  item-index__label">
								<div>{index + 1}</div>
							</div>
							<div className="table__width-number__column table__width-number__column--right">
								<TablePartRow
									rowParentClass="table__width-number__column-row"
									leftPart="Идентификатор пользователя"
									rightPart={x.userId}
								/>
								<TablePartRow
									rowParentClass="table__width-number__column-row"
									leftPart="Время создания копии"
									rightPart={formatDateTime(x.copyTime)}
								/>
								<TablePartRow
									leftPart="Вероятность"
									rightPart={ceilNumberWithPercent(x.confidence)}
									rowParentClass="table__width-number__column-row"
								/>
								{x.parameters.map((x, index) => {
									return (
										<TablePartRow
											key={`${x.value}-${index}`}
											rowParentClass="table__width-number__column-row"
											leftPart={x.name}
											rightPart={x.value}
										/>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
