import React from "react";
import { ExcelIcon } from "./ExcelIcon";
import classnames from "classnames";

export const svgIconList = {
	excel: <ExcelIcon />,
};

export type IconsType = keyof typeof svgIconList;

export interface SvgContainerProps extends React.HTMLAttributes<HTMLSpanElement> {
	icon: IconsType;
	title: string;
	hasHover?: boolean;
}

export const SvgContainer: React.FC<SvgContainerProps> = ({ icon, className = "", hasHover = false, ...props }) => {
	return (
		<span
			className={classnames(`svg-container`, className, {
				"make-hover has-cursor": hasHover,
			})}
			{...props}
		>
			{svgIconList[icon]}
		</span>
	);
};
