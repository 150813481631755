import { SettingsService } from "../../api/settings";
import { ThunkType } from "../index";
import { notificationSettingsActions } from "./index";
import { NotificationsSettingsResponse } from "../../models/notificationsSettings/response";

export const getNotificationsSettingsAsync = (): ThunkType => {
	return async (dispatch) => {
		const response = await SettingsService.getNotificationsSettings();

		response.map((data) => {
			dispatch(notificationSettingsActions.getNotificationsSettings(data.data));
			return data;
		});
	};
};

export const setNotificationsSettingsAsync = (data: NotificationsSettingsResponse): ThunkType => {
	return async (dispatch) => {
		await SettingsService.setNotificationsSettings(data).then(() => {
			dispatch(getNotificationsSettingsAsync());
		});
	};
};
