import React from "react";
import "./WhiteClickedTooltip.scss";
import classNames from "classnames";
import { TooltipConstants } from "../Tooltip/constants";
import { useBoolean } from "../../../hooks/useBoolean";
import { useComponentVisible } from "../../../hooks/useComponentVisible";

interface ChildrenProps {
	onWrapperClicked: () => void;
	hideTooltip: () => void;
	innerRef: React.MutableRefObject<HTMLDivElement | null>;
}

interface WhiteTooltipProps {
	tooltipContent: React.ReactNode | React.ReactNodeArray;
	children: (props: ChildrenProps) => React.ReactNode | React.ReactNodeArray;
	position?: "left" | "right";
	tooltipContainerStyles?: React.CSSProperties;
}

export const WhiteClickedTooltip: React.FC<WhiteTooltipProps> = ({
	children,
	tooltipContent,
	position = "left",
	tooltipContainerStyles = {},
}) => {
	const { value: visibleTooltip, setToggle: toggleVisibleTooltip, setFalse: hideTooltip } = useBoolean();
	const { ref } = useComponentVisible(hideTooltip);
	const innerRef = React.useRef<HTMLDivElement | null>(null);
	const tooltipRef = React.useRef<HTMLDivElement | null>(null);

	React.useEffect(() => {
		const width = innerRef.current?.getBoundingClientRect().width || 0;
		const PART = 2;

		tooltipRef.current?.style.setProperty("--triangle-gutter", `${width / PART}px`);
	}, []);

	return (
		<>
			<div className="white-tooltip__wrapper" ref={ref}>
				{children({
					onWrapperClicked: toggleVisibleTooltip,
					innerRef,
					hideTooltip,
				})}
				<div
					ref={tooltipRef}
					className={classNames("tooltip", {
						visible: visibleTooltip,
						[`position-left`]: position === "left",
						[`position-right`]: position === "right",
					})}
					data-testid={TooltipConstants.Container}
				>
					<div className="tooltip-inner" style={tooltipContainerStyles}>
						{tooltipContent}
					</div>
				</div>
			</div>
		</>
	);
};
