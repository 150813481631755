import { DocumentOptionType, InvestigatesType, PageOption } from "../../models/investigates/Investigates";

export type CustomFileListType = Blob & {
	path?: string;
	name: string;
};

export interface InvestigateStateType {
	documentsOptions: ReadonlyArray<DocumentOptionType>;
	pagesOptions: DocumentOptionType[];
	files: CustomFileListType[];
	documentId: string;
	imageUrl: string | null;
	error: string | null;
	pageNumber: string | null;
	resultInvestigate: InvestigatesType | null;
	loading: boolean;
	currentValueNameDoc: DocumentOptionType | null;
	currentValuePageNumber: PageOption | null;
	investigateId?: string | null;
}

export const investigateInitialState: InvestigateStateType = {
	documentsOptions: [],
	pagesOptions: [],
	documentId: "",
	pageNumber: null,
	imageUrl: null,
	files: [],
	resultInvestigate: null,
	loading: false,
	currentValueNameDoc: null,
	currentValuePageNumber: null,
	error: null,
	investigateId: null,
};
