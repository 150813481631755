import { ILicense } from "../models/license/license";
import { EitherResponse } from "../models/common";
import { Api } from "./api";
import { API_PATH } from "./configs";

export class LicenseService {
	public static async getLicense(): Promise<EitherResponse<ILicense>> {
		return await new Api().get<ILicense>(`/${API_PATH.license.getLicense}`);
	}

	public static async sendLicenseFile(body: FormData): Promise<EitherResponse<null>> {
		return await new Api().post<FormData, null>(`/${API_PATH.license.sendLicense}`, body);
	}
}
