import React from "react";
import { TablePartRow } from "../../Modals/TableModal/TablePartRow";
import { ceilNumberWithPercent, convertObjectToStringCortege } from "../../../helpers/commonHelpers/commonHelpers";
import { JobsRecords } from "../../../models/jobs/jobs";
import { NameValueType } from "../../../models/common";
import { formatDateTime } from "../../../helpers/dates/dates";

const vocabulary: { [key: string]: string } = {
	version: "Версия",
	fileName: "Имя файла",
	status: "Статус",
	externalDocumentId: "ID документа во внешней системе",
	fileSize: "Размер файла",
	externalSystemId: "Внешняя система",
	pageCount: "Количество страниц",
	alreadyUploaded: "Документ был ранее загружен",
	fileType: "Тип файла",
	pageNumber: "Номер страницы",
	copyTime: "Время создания копии",
	initiator: "Инициатор расследования",
	confidence: "Вероятность",
	userId: "Получатель копии",
};

const vocabularySymbols: { [key: string]: (value: string) => string } = {
	confidence: (value) => ceilNumberWithPercent(value),
	copyTime: (value) => formatDateTime(value),
	alreadyUploaded: () => "Да",
};

const renderVocabularyWithSymbol = ({ name, value }: NameValueType): string => {
	return `${vocabularySymbols[name]?.(value) ?? value}`.trim();
};

const renderTableRowList = (data: JobsRecords["eventData"] | null) => {
	const correctData: Partial<JobsRecords["eventData"]> = { ...data } ?? {};

	if (correctData.fileName) {
		delete correctData.fileName;
	}

	const convertData = convertObjectToStringCortege(correctData);

	return convertData.map(([name, value]) => {
		if (name === "alreadyUploaded" && !value) {
			return null;
		}
		return (
			<TablePartRow
				key={name}
				rowParentClass="table__width-parts__column-row"
				leftPart={vocabulary[name] ?? name}
				rightPart={renderVocabularyWithSymbol({ name, value })}
			/>
		);
	});
};

interface Props {
	data: JobsRecords["eventData"] | null;
}

export const TooltipTableJob: React.FC<Props> = ({ data }) => {
	return (
		<>
			<div className="table__width-grid table__width-parts">
				<div className="table__width-number__body">
					<div className="table__width-number__body-row">
						<div className="table__width-number__column">{renderTableRowList(data)}</div>
					</div>
				</div>
			</div>
		</>
	);
};
