const LEFT_KIND = "left";
const RIGHT_KIND = "right";

type Fail<L> = { kind: typeof LEFT_KIND; leftValue: L };
type Result<R> = { kind: typeof RIGHT_KIND; rightValue: R };

type EitherValue<F, R> = Fail<F> | Result<R>;

export class Either<F, R> {
	private constructor(readonly value: EitherValue<F, R>) {}

	public isLeft(): boolean {
		return this.value.kind === LEFT_KIND;
	}

	public isRight(): boolean {
		return this.value.kind === RIGHT_KIND;
	}

	private fold<T>(errorFn: (error: F) => T, resultFn: (result: R) => T): T {
		switch (this.value.kind) {
			case LEFT_KIND:
				return errorFn(this.value.leftValue);
			case RIGHT_KIND:
				return resultFn(this.value.rightValue);
		}
	}

	public map<T>(fn: (r: R) => T): Either<F, T> {
		return this.flatMap((r) => Either.right(fn(r)));
	}

	public flatMap<T>(fn: (right: R) => Either<F, T>): Either<F, T> {
		return this.fold(
			(leftValue) => Either.left(leftValue),
			(rightValue) => fn(rightValue)
		);
	}

	public getOrThrow(errorMessage?: string, callBack?: (errorMessage: string) => void): R {
		const throwFn = () => {
			const message = errorMessage ? errorMessage : "An error has ocurred: " + this.value;
			callBack?.(message);

			throw Error(message);
		};

		return this.fold(
			() => throwFn(),
			(rightValue) => rightValue
		);
	}

	public getOrElse(defaultValue: R): R {
		return this.fold(
			() => defaultValue,
			(someValue) => someValue
		);
	}

	static left<L, R>(value: L): Either<L, R> {
		return new Either<L, R>({ kind: LEFT_KIND, leftValue: value });
	}

	static right<L, R>(value: R): Either<L, R> {
		return new Either<L, R>({ kind: RIGHT_KIND, rightValue: value });
	}
}
