import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { storyInitialState, StoryInitialStateType } from "./initialState";
import { Pagination } from "../../models/common";

const commonSlice = createSlice({
	name: "story",
	initialState: storyInitialState,
	reducers: {
		fetchInvestigatesStart(state: StoryInitialStateType) {
			state.loading = true;
		},
		fetchInvestigatesSuccess(
			state: StoryInitialStateType,
			action: PayloadAction<{
				investigates: StoryInitialStateType["investigates"];
				metadata?: Pagination;
			}>
		) {
			state.investigates = action.payload.investigates;
			state.investigatesMetadata = action.payload.metadata;
			state.loading = false;
		},
		fetchInvestigatesError(state: StoryInitialStateType, action: PayloadAction<string>) {
			state.error = action.payload;
			state.loading = false;
		},
		fetchSelectedInvestigateError(state: StoryInitialStateType, action: PayloadAction<string>) {
			state.error = action.payload;
			state.loading = false;
		},
		fetchSelectedInvestigateStart(state: StoryInitialStateType) {
			state.loading = true;
		},
		fetchSelectedInvestigateSuccess(
			state: StoryInitialStateType,
			action: PayloadAction<StoryInitialStateType["selectedInvestigate"]>
		) {
			state.loading = false;
			state.selectedInvestigate = action.payload;
		},
		clearSelectedInvestigate(state: StoryInitialStateType) {
			state.selectedInvestigate = null;
		},
	},
});

export const storyActions = commonSlice.actions;

export default commonSlice.reducer;
