import React from "react";
import searchIcon from "../../../assets/searchIcon/searchIcon.svg";
import classNames from "classnames";

export interface FilterInputProps {
	name: string;
	filterValue: string | number;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	className?: string;
	placeholder?: string;
}

export const TableFilter: React.FC<FilterInputProps> = ({
	placeholder = "",
	className,
	name,
	filterValue,
	onChange,
}) => {
	return (
		<>
			<input
				className={classNames(className, `filter-name-doc`)}
				type="text"
				value={filterValue}
				onChange={onChange}
				name={name}
				placeholder={placeholder}
			/>
			<i style={{ position: "relative", top: "4px" }}>
				<img src={searchIcon} />
			</i>
		</>
	);
};
