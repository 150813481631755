import React from "react";

export const ExcelIcon: React.FC = () => {
	return (
		<>
			<svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M0.498 9C0.498 9.41421 0.833787 9.75 1.248 9.75C1.66221 9.75 1.998 9.41421 1.998 9H0.498ZM1.24994 22.25C0.835726 22.25 0.49994 22.5858 0.49994 23C0.49994 23.4142 0.835726 23.75 1.24994 23.75V22.25ZM6.38088 12.4056C6.60487 12.0571 6.504 11.5931 6.15557 11.3691C5.80714 11.1451 5.3431 11.246 5.11912 11.5944L6.38088 12.4056ZM1.88088 11.5944C1.65689 11.246 1.19286 11.1451 0.844432 11.3691C0.496004 11.5931 0.395127 12.0571 0.619116 12.4056L1.88088 11.5944ZM0.3897 18.5641C0.148943 18.9011 0.227012 19.3695 0.564071 19.6103C0.901131 19.8511 1.36954 19.773 1.6103 19.4359L0.3897 18.5641ZM5.3897 19.4359C5.63046 19.773 6.09887 19.8511 6.43593 19.6103C6.77299 19.3695 6.85106 18.9011 6.6103 18.5641L5.3897 19.4359ZM18.9571 4.20711L19.4874 3.67678L18.9571 4.20711ZM16.0429 1.29289L16.5732 0.762563L16.0429 1.29289ZM1.998 9V2H0.498V9H1.998ZM2.248 1.75H15.3358V0.25H2.248V1.75ZM15.5126 1.82322L18.4268 4.73744L19.4874 3.67678L16.5732 0.762563L15.5126 1.82322ZM18.5 4.91421V22H20V4.91421H18.5ZM18.25 22.25H1.24994V23.75H18.25V22.25ZM13.5 1V5H15V1H13.5ZM15.25 6.75H19.25V5.25H15.25V6.75ZM5.11912 11.5944L2.86912 15.0944L4.13088 15.9056L6.38088 12.4056L5.11912 11.5944ZM4.13088 15.0944L1.88088 11.5944L0.619116 12.4056L2.86912 15.9056L4.13088 15.0944ZM2.8897 15.0641L0.3897 18.5641L1.6103 19.4359L4.1103 15.9359L2.8897 15.0641ZM2.8897 15.9359L5.3897 19.4359L6.6103 18.5641L4.1103 15.0641L2.8897 15.9359ZM18.5 22C18.5 22.1381 18.3881 22.25 18.25 22.25V23.75C19.2165 23.75 20 22.9665 20 22H18.5ZM18.4268 4.73744C18.4737 4.78432 18.5 4.84791 18.5 4.91421H20C20 4.45008 19.8156 4.00497 19.4874 3.67678L18.4268 4.73744ZM13.5 5C13.5 5.9665 14.2835 6.75 15.25 6.75V5.25C15.1119 5.25 15 5.13807 15 5H13.5ZM15.3358 1.75C15.4021 1.75 15.4657 1.77634 15.5126 1.82322L16.5732 0.762563C16.245 0.434374 15.7999 0.25 15.3358 0.25V1.75ZM1.998 2C1.998 1.86193 2.10993 1.75 2.248 1.75V0.25C1.2815 0.25 0.498 1.0335 0.498 2H1.998Z"
					fill="#5176A2"
				/>
			</svg>
		</>
	);
};
