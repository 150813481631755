import { configureStore } from "@reduxjs/toolkit";
import reduxThunk, { ThunkAction } from "redux-thunk";
import documents from "./documents";
import investigates from "./investigate";
import jobs from "./jobs";
import notificationsSettings from "./settings";
import notify from "./notify";
import externalSystems from "./externalSystems";
import story from "./story";
import { AnyAction } from "redux";

export const store = configureStore({
	reducer: { documents, investigates, jobs, notify, story, externalSystems , notificationsSettings},
	middleware: [reduxThunk],
});

export type NewRootState = ReturnType<typeof store.getState>;
// eslint-disable-next-line @typescript-eslint/ban-types
export type ThunkType<T = void> = ThunkAction<Promise<T>, NewRootState, {}, AnyAction>;
